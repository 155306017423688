import { useEffect, useState } from "react";
import Highcharts from 'highcharts';
import HighChartsSankey from 'highcharts/modules/sankey';
import axios from "axios";
import { ClipLoader } from 'react-spinners';
import { useLocation, useSearchParams, NavLink } from "react-router-dom";
import { customSort, getApiServrUrl } from "../utils/utils";
import { DownloadButton } from "./download_button";

HighChartsSankey(Highcharts);

const UniverseCompanyTalentComponent = () => {
    const [talentFlowData, setTalentFlowData] = useState({});
    const [selectedCheckbox, setSelectedCheckbox] = useState({ id: 8, value: getPastDate('10 years'), label: '10 years' });
    const [totalInflow, setTotalInflow] = useState(0);
    const [totalOutflow, setTotalOutflow] = useState(0);
    const [privateInflow, setPrivateInflow] = useState(0);
    const [pendingOutflow, setPendingOutflow] = useState(0);

    const [hiringDensityData, setHiringDensityData] = useState([]);
    const [leavingDensityData, setLeavingDensityData] = useState([]);
    const [companyName, setCompanyName] = useState('');
    
    const [geo1Values, setGeo1Values] = useState([]);
    const [selectedGeo1, setSelectedGeo1] = useState('option1');
    const [deptValues, setDeptValues] = useState([]);
    const [selectedDept, setSelectedDept] = useState('All');
    const [seniorityValues, setSeniorityValues] = useState([]);
    const [selectedSeniority, setSelectedSeniority] = useState('All');
    
    const [isPageLoading, setIsPageLoading] = useState(false);

    const [searchParams] = useSearchParams();
    const location = useLocation();

    const universeId = searchParams && searchParams.get('universe_id') || location.state && location.state.universeId;
    const companyId = searchParams && searchParams.get('company_id') || location.state && location.state.companyId;
    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.accessToken;

    const universeSummaryPath = `/universe/summary?universe_id=${universeId}&access_token=${accessToken}`;
    const teamGrowthPath = `/universe/company/team/growth?universe_id=${universeId}&company_id=${companyId}&access_token=${accessToken}`;
    const companyGrowthPath = `/universe/company/growth?universe_id=${universeId}&company_id=${companyId}&access_token=${accessToken}`;
    
    const timeOptions = [
        { id: 1, value: getPastDate('3 months'), label: '3 months' },
        { id: 2, value: getPastDate('6 months'), label: '6 months' },
        { id: 3, value: getPastDate('1 year'), label: '1 year' },
        { id: 4, value: getPastDate('2 years'), label: '2 years' },
        { id: 5, value: getPastDate('3 years'), label: '3 years' },
        { id: 6, value: getPastDate('5 years'), label: '5 years' },
        { id: 7, value: getPastDate('7 years'), label: '7 years' },
        { id: 8, value: getPastDate('10 years'), label: '10 years' },
    ];

    function getPastDate(duration) {
        switch (duration) {
            case '3 months':
                return new Date(
                    new Date().getUTCFullYear(),
                    new Date().getUTCMonth() - 3,
                    new Date().getUTCDate(),
                );
            case '6 months':
                return new Date(
                    new Date().getUTCFullYear(),
                    new Date().getUTCMonth() - 6,
                    new Date().getUTCDate(),
                );
            case '1 year':
                return new Date(
                    new Date().getUTCFullYear(),
                    new Date().getUTCMonth() - 12,
                    new Date().getUTCDate(),
                );
            case '2 years':
                return new Date(
                    new Date().getUTCFullYear(),
                    new Date().getUTCMonth() - 24,
                    new Date().getUTCDate(),
                );
            case '3 years':
                return new Date(
                    new Date().getUTCFullYear(),
                    new Date().getUTCMonth() - 36,
                    new Date().getUTCDate(),
                );
            case '5 years':
                return new Date(
                    new Date().getUTCFullYear(),
                    new Date().getUTCMonth() - 60,
                    new Date().getUTCDate(),
                );
            case '7 years':
                return new Date(
                    new Date().getUTCFullYear(),
                    new Date().getUTCMonth() - 84,
                    new Date().getUTCDate(),
                );
            case '10 years':
                return new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() - 120,
                    new Date().getDate(),
                );
            default:
                return new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() - 120,
                    new Date().getDate(),
                );
        };
    };

    const handleCheckboxChange = (option) => {
        setSelectedCheckbox(option);
    };
    
    const handleGeo1Change = (event) => {
        setSelectedGeo1(event.target.value);
    };

    const handleDeptChange = (event) => {
        setSelectedDept(event.target.value);
    };

    const handleSeniorityChange = (event) => {
        setSelectedSeniority(event.target.value);
    };

    const getTalentFlowData = async() => {
        let apiUrl = getApiServrUrl();
        let talentInUrl = apiUrl + process.env.REACT_APP_API_TALENT_INFLOW_ENDPOINT;
        let talentOutUrl = apiUrl + process.env.REACT_APP_API_TALENT_OUTFLOW_ENDPOINT;

        let data = {
            // "company_ids": [919192, , 926354, 950648, 907611, ],
            "company_ids": [parseInt(companyId)],
        };
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
        };

        try {
            // talent in
            console.log(`CompanyTalent: Fetching talent in data from ${talentInUrl}...`);
            const response = await axios.post(talentInUrl, data, config);
            const jsonData = JSON.parse(response.data.data.sankey_in_history);
            // setSankeyInData(jsonData);

            let geo1Values = [... new Set(jsonData.map((item) => item.geo1))];
            geo1Values.push("All");
            let sortedGeo1Values = customSort(geo1Values, ['All', 'North America', 'Europe, Middle East & Africa', 'Latin America', 'Asia/Pacific']);
            setGeo1Values(sortedGeo1Values);
            setSelectedGeo1("All");

            let deptValues = [... new Set(jsonData.map((item) => item.department_group))];
            deptValues.push('All');
            const sortedDeptValues = customSort(deptValues, ['All', 'Sales', 'Product']);
            setDeptValues(sortedDeptValues);
            setSelectedDept('All');

            let seniorityValues = [... new Set(jsonData.map((item) => item.seniority_group))];
            seniorityValues.push('All');
            const sortedSeniorityOrder = [
                "All",
                "Individual Contributor",
                "Advisor / Board",
                "Executive",
                "Leadership",
                "Management",
                "Student / Intern",
                "Unsure"
            ];
            const sortedSeniorityValues = customSort(seniorityValues, sortedSeniorityOrder);
            setSeniorityValues(sortedSeniorityValues);
            setSelectedSeniority('All');

            setCompanyName(jsonData[0]['company_name']);

            const hiringDensityDataJson = JSON.parse(response.data.data.sankey_in_density);
            setHiringDensityData(hiringDensityDataJson); 

            // talent out
            console.log(`CompanyTalent: Fetching talent out data from ${talentOutUrl}...`);
            const response2 = await axios.post(talentOutUrl, data, config);
            const jsonData2 = JSON.parse(response2.data.data.sankey_out_history);
            // setSankeyOutData(jsonData2);

            const leavingDensityDataJson = JSON.parse(response2.data.data.sankey_out_density);
            setLeavingDensityData(leavingDensityDataJson);

            const talentFlowData = {
                "talentIn": jsonData,
                "talentOut": jsonData2,
            };
            setTalentFlowData(talentFlowData);

            setIsPageLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    function getSankeyChartOptions () {
        if (talentFlowData === null || talentFlowData === undefined) {
            return {};
        }
        else if (talentFlowData['talentIn'] === null || talentFlowData['talentIn'] === undefined) {
            return {};
        }
        else if (talentFlowData['talentOut'] === null || talentFlowData['talentOut'] === undefined) {
            return {};
        }

        let sankeyInData = talentFlowData['talentIn'];
        let sankeyOutData = talentFlowData['talentOut'];
        let filteredSankeyInData = [];
        let filteredSankeyOutData = [];
        if (selectedGeo1 !== "All" || selectedDept !== "All" || selectedSeniority !== "All") {
            let isGeo1Selected = selectedGeo1 !== "All";
            let isDeptSelected = selectedDept !== "All";
            let isSenioritySelected = selectedSeniority !== "All";

            if (isGeo1Selected) {
                filteredSankeyInData = sankeyInData.filter((item) => item.geo1 === selectedGeo1);
                filteredSankeyOutData = sankeyOutData.filter((item) => item.geo1 === selectedGeo1);
                if (isDeptSelected) {
                    filteredSankeyInData = filteredSankeyInData.filter((item) => item.department_group === selectedDept);
                    filteredSankeyOutData = filteredSankeyOutData.filter((item) => item.department_group === selectedDept);
                }
                if (isSenioritySelected) {
                    filteredSankeyInData = filteredSankeyInData.filter((item) => item.seniority_group === selectedSeniority);
                    filteredSankeyOutData = filteredSankeyOutData.filter((item) => item.seniority_group === selectedSeniority);
                }
            }
            if (isDeptSelected) {
                filteredSankeyInData = sankeyInData.filter((item) => item.department_group === selectedDept);
                filteredSankeyOutData = sankeyOutData.filter((item) => item.department_group === selectedDept);
                if (isGeo1Selected) {
                    filteredSankeyInData = filteredSankeyInData.filter((item) => item.geo1 === selectedGeo1);
                    filteredSankeyOutData = filteredSankeyOutData.filter((item) => item.geo1 === selectedGeo1);
                }
                if (isSenioritySelected) {
                    filteredSankeyInData = filteredSankeyInData.filter((item) => item.seniority_group === selectedSeniority);
                    filteredSankeyOutData = filteredSankeyOutData.filter((item) => item.seniority_group === selectedSeniority);
                }
            }
            if (isSenioritySelected) {
                filteredSankeyInData = sankeyInData.filter((item) => item.seniority_group === selectedSeniority);
                filteredSankeyOutData = sankeyOutData.filter((item) => item.seniority_group === selectedSeniority);
                if (isGeo1Selected) {
                    filteredSankeyInData = filteredSankeyInData.filter((item) => item.geo1 === selectedGeo1);
                    filteredSankeyOutData = filteredSankeyOutData.filter((item) => item.geo1 === selectedGeo1);
                }
                if (isDeptSelected) {
                    filteredSankeyInData = filteredSankeyInData.filter((item) => item.department_group === selectedDept);
                    filteredSankeyOutData = filteredSankeyOutData.filter((item) => item.department_group === selectedDept);
                }
            }
        }
        else {
            filteredSankeyInData = sankeyInData;
            filteredSankeyOutData = sankeyOutData;
        }

        if (selectedCheckbox.label !== '10 years') {
            let timeDurationMonth = selectedCheckbox.value.getMonth() + 1;
            let timeDurationYear = selectedCheckbox.value.getFullYear();
            let startTime = new Date(timeDurationYear, timeDurationMonth, 1).getTime();
            filteredSankeyInData = filteredSankeyInData.filter((item) => {
                let itemDate = new Date(item['date_from']);
                let dateStr = itemDate.getUTCFullYear() + '/' + (itemDate.getUTCMonth() + 1) + '/' + itemDate.getUTCDate();
                let date = new Date(dateStr);
                return date.getTime() >= startTime
            });
            filteredSankeyOutData = filteredSankeyOutData.filter((item) => {
                let itemDate = new Date(item['date_to']);
                let dateStr = itemDate.getUTCFullYear() + '/' + (itemDate.getUTCMonth() + 1) + '/' + itemDate.getUTCDate();
                let date = new Date(dateStr);
                return date.getTime() >= startTime
            });

        }


        let companyEmpInCountMap = {};
        let totalEmpInCount = 0;

        for (let i = 0; i < filteredSankeyInData.length; i++) {
            totalEmpInCount += filteredSankeyInData[i]['employee_count'];
            
            if (filteredSankeyInData[i]['past_company_name'] in companyEmpInCountMap) {
                companyEmpInCountMap[filteredSankeyInData[i]['past_company_name']] += filteredSankeyInData[i]['employee_count'];
            } else {
                companyEmpInCountMap[filteredSankeyInData[i]['past_company_name']] = filteredSankeyInData[i]['employee_count'];
            };
        };
        // companyEmpInCountMap['total'] = totalEmpInCount;
        const companyEmpInCountMapSortedByCount = Object.entries(companyEmpInCountMap).sort((a, b) => b[1] - a[1]);
        // setSankeyInCompanyCountMap(companyEmpInCountMapSortedByCount);

        let totalEmpOutCount = 0;
        let companyEmpOutCountMap = {};
        for (let i = 0; i < filteredSankeyOutData.length; i++) {
            totalEmpOutCount += filteredSankeyOutData[i]['employee_count'];
            
            if (filteredSankeyOutData[i]['current_company_name'] in companyEmpOutCountMap) {
                companyEmpOutCountMap[filteredSankeyOutData[i]['current_company_name']] += filteredSankeyOutData[i]['employee_count'];
            } else {
                companyEmpOutCountMap[filteredSankeyOutData[i]['current_company_name']] = filteredSankeyOutData[i]['employee_count'];
            };
        };

        // companyEmpOutCountMap['total'] = totalEmpOutCount;
        const companyEmpOutCountMapSortedByCount = Object.entries(companyEmpOutCountMap).sort((a, b) => b[1] - a[1]);
        // setSankeyOutCompanyCountMap(companyEmpOutCountMapSortedByCount);

        setTotalInflow(totalEmpInCount);
        setTotalOutflow(totalEmpOutCount);
        setPrivateInflow(companyEmpInCountMap['Private'] || 0);
        setPendingOutflow(companyEmpOutCountMap['Pending'] || 0);
       
        const sankeyInSeriesData = [];
        const sankeyOutSeriesData = [];

        let count = 0;
        for (let i = 0; i < companyEmpInCountMapSortedByCount.length; i++) {
            if (companyEmpInCountMapSortedByCount[i][0] !== 'Private') {
                sankeyInSeriesData.push({
                    id: count,
                    from: companyEmpInCountMapSortedByCount[i][1] + ':' + companyEmpInCountMapSortedByCount[i][0],
                    to: companyName,
                    weight: companyEmpInCountMapSortedByCount[i][1],
                    // color: '#C9D4F5',
                    // color: '#B3D6AB',
                    color: '#999999',
                });
            };
            if (count === 25){
                break;
            }
            count += 1;
        };
        
        for (let i = 0; i < companyEmpOutCountMapSortedByCount.length; i++) {
            if (companyEmpOutCountMapSortedByCount[i][0] !== 'Pending') {
                sankeyOutSeriesData.push({
                    id: count,
                    from: companyName,
                    to: companyEmpOutCountMapSortedByCount[i][0] + ':' + companyEmpOutCountMapSortedByCount[i][1],
                    weight: companyEmpOutCountMapSortedByCount[i][1],
                    // color: '#C9D4F5',
                    // color: '#B3D6AB',
                    color: '#999999',
                });
            };
            if (count === 50) {
                break;
            }
            count += 1;
        };

        const sankeyChartSeriesData = sankeyInSeriesData.concat(sankeyOutSeriesData);
        
        return {
            chart: {
                height: '60%',
                renderTo: 'talent-flow-chart-container',
            },

			title: {
				text: 'Talent inflow/outflow',
				align: 'left'
			},
			
			legend: {
				enabled: true,
			},
			
			series: [{
				keys: ['from', 'to', 'weight'],
                data: sankeyChartSeriesData,
                type: 'sankey',
                name: 'Talent Flow',
                // links: links,
                linkOpacity: 0.5,
                // nodes: nodes,
            }],

            plotOptions: {
                sankey: {
                    nodeWidth: 10,
                },
            },

			credits: {
				enabled: false
			},

            xAxis: {
                type: 'datetime'
              },
              yAxis: {
                title: {
                  text: 'Value'
                }
              },
	
		};
    };

    function getHiringDensityChartOptions() {
        const categories = [];
        const hiringDensityDataSeries = hiringDensityData.map((item) => {
            categories.push(item.bins);
            return [item.bins, item.employee_percentage * 100];
        });

        return {
			chart: {
				zoomType: 'x',
                type: 'column',
			},
			title: {
				text: 'Hiring Density - Inflow',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
            xAxis: {
                categories: categories,
                title: {
                    text: 'Number of employees hired from a single org'
                },
            },
			yAxis: {
				title: {
					text: '% Employees hired by group size'
				},
                labels: {
					format: '{value}%'
				}
			},
			legend: {
				enabled: true,
			},
			
			series: [
				{
                    name: 'Hiring Density',
					type: 'column',
					data: hiringDensityDataSeries,
                    color: 'green',
				},
			],

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    pointWidth: 10,
                },
            },
	
		};

    };

    function getLeavingDensityChartOptions() {
        const categories = [];
        const leavingDensityDataSeries = leavingDensityData.map((item) => {
            categories.push(item.bins);
            return [item.bins, item.employee_percentage * 100];
        });

        return {
			chart: {
				zoomType: 'x',
                type: 'column',
			},
			title: {
				text: 'Leaving Density - Outflow',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
            xAxis: {
                categories: categories,
                title: {
                    text: 'Number of employees departed to a single org'
                },
                
            },
			yAxis: {
				title: {
					text: '% Employees hired by group size'
				},
                labels: {
					format: '{value}%'
				}
			},
			legend: {
				enabled: true,
			},
			
			series: [
				{   
                    name: 'Leaving Density',
					type: 'column',
					data: leavingDensityDataSeries,
                    color: 'red',
				},
			],

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    pointWidth: 10,
                },
            },
	
		};

    };

    useEffect(() => {
        setIsPageLoading(true);
        getTalentFlowData();
    }, []);

    useEffect(() => {
        if (isPageLoading === false) {
            const sankeyChartOptions = getSankeyChartOptions();
            Highcharts.chart('talent-flow-chart-container', sankeyChartOptions);

            const hiringDensityChartOptions = getHiringDensityChartOptions();
            Highcharts.chart('hiring-density-chart-container', hiringDensityChartOptions);

            const leavingDensityChartOptions = getLeavingDensityChartOptions();
            Highcharts.chart('leaving-density-chart-container', leavingDensityChartOptions);
        };

    }, [talentFlowData, selectedGeo1, selectedDept, selectedSeniority, selectedCheckbox]);

    return (
        <div>
            <div style={{ display: 'flex'}}>
                <div style={{display: 'flex', justifyContent: 'flex-start', padding: '10px'}}>
                    <NavLink
                        to={universeSummaryPath}
                        state={{universeId: universeId, accessToken: accessToken}}
                    > Universe Summary
                    </NavLink>
                </div>
                <div style={{display: 'flex', flex: '1', justifyContent: 'flex-end', padding: '10px'}}>
                    <NavLink 
                        to={companyGrowthPath}
                        state={{universeId: universeId, companyId: companyId, companyName: companyName, accessToken: accessToken}}
                    > Company Growth
                    </NavLink>
                    <div style={{ padding: '10px'}}></div>
                    <NavLink 
                        to={teamGrowthPath}
                        state={{universeId: universeId, companyId: companyId, companyName: companyName, accessToken: accessToken}}
                    > Team Growth
                    </NavLink>
                </div>
            </div>
            <div style={{textAlign: 'center'}}>
                <h1 style={{marginTop: '0px', marginBottom: '10px'}}>
                    Talent Flow: {companyName}
                </h1>
            </div>
            { isPageLoading ? (
                <div style={{ textAlign: "center" }} ><ClipLoader/> </div>
            ) : (
            <div>
            <div style={{textAlign: 'center'}}>
                <label>
                    Region:&nbsp;
                    <select value={selectedGeo1} onChange={handleGeo1Change}>
                        {
                            geo1Values.map((value) => (
                                    <option key={value} value={value}>
                                    {value}
                                    </option>
                                )
                            )
                        }
                    </select>
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                    Department:&nbsp;
                    <select value={selectedDept} onChange={handleDeptChange}>
                        {
                            deptValues.map((value) => (
                                    <option key={value} value={value}>
                                    {value}
                                    </option>
                                )
                            )
                        }
                    </select>
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                    Seniority:&nbsp;
                    <select value={selectedSeniority} onChange={handleSeniorityChange}>
                        {
                            seniorityValues.map((value) => (
                                    <option key={value} value={value}>
                                    {value}
                                    </option>
                                )
                            )
                        }
                    </select>
                </label>
            </div>
            <br/>
            <div className="checkbox-group-container">
                { timeOptions.map((timeOption) => (
                    <div key={timeOption.id} className="checkbox-container" >
                        <input
                            type="radio"
                            id={timeOption.id}
                            name="timeOption"
                            // checked={selectedCheckbox === timeOption}
                            defaultChecked={timeOption.label === "10 years"}
                            onChange={() => handleCheckboxChange(timeOption)}
                        />
                        <label htmlFor={timeOption.id}>{timeOption.label}</label>
                    </div>
                ))}
            </div>
            <div className='chart-container'>
                <div className='chart-container-left' >
                    <div id="talent-flow-chart-container"></div>
                    <div>
                        {/* <div style={{width: '100%', padding: '10px'}}> */}
                        <div style={{display: 'flex'}}>

                            <div style={{display: 'flex', flexDirection: 'column', margin: '10px', width: '50%'}}>
                                <ul style={{ listStyleType: 'none', textAlign: 'left'}}>
                                    <li>Total Inflow: {totalInflow}</li>
                                    <li>Private: {privateInflow}</li>
                                </ul>
                            </div>
                            <div style={{display: 'flex', flex: '1', alignItems: 'stretch', flexDirection: 'column', margin: '15px'}}>
                                <ul style={{ listStyleType: 'none', textAlign: 'right', marginRight: '30px'}}>
                                    <li>Total Outflow: {totalOutflow}</li>
                                    <li>Pending: {pendingOutflow}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='chart-container-right'>
                    <div className = 'chart-container-right-child-sankey' id="hiring-density-chart-container">
                        <h3>Hiring Density</h3>
                        
                    </div>
                    <div className = 'chart-container-right-child-sankey' id="leaving-density-chart-container">
                        <h3>Leaving Density</h3>
                        
                    </div>
                </div>
            </div>
            <div style={{ margin:'0 auto', textAlign: 'center'}}>
                <DownloadButton 
                    data = {talentFlowData['talentIn']} 
                    companyName={companyName} 
                    buttonText = {'Download Talent Inflow Data'}
                    fileName = { `${companyName}_talent_inflow_data.csv` }
                    talentInflow = {true}
                />
                &nbsp;&nbsp;
                <DownloadButton 
                    data = {talentFlowData['talentOut']} 
                    companyName={companyName} 
                    buttonText={'Download Talent Outflow Data'}
                    fileName = { `${companyName}_talent_outflow_data.csv` }
                    talentOutflow = {true}
                />
            </div>
            <div style={{ margin: '10px'}}>
                Notes:
                <ul>
                    <li>Talent flow based on the total number of public profiles available</li>
                    <li>Top 25 companies listed on inflow and outflow</li>
                    <li>'Private' inflow means that the employee started at the target company, but the employee's previous experience is not known</li>
                    <li>'Pending' outflow means that the employee has ended his/her experience at the target company, but has not yet specified a subsequent experience</li>
                    <li>Hiring Density and Leaving Density calculations do not include Private and Pending inflow/outflows</li>
                </ul>
            </div>
            </div> )}
        </div>
    );
};

export default UniverseCompanyTalentComponent;
