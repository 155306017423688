import React, { useEffect, useState } from 'react';
import { NavLink, useSearchParams, useLocation } from 'react-router-dom';
import { getApiServrUrl, getGoogleAnalyticsId } from '../utils/utils';
import axios from 'axios';

const DemoHomePageLayout = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const [isUnAuthorized, setIsUnAuthorized] = useState(false);

    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.accessToken;

    const metaDemoPath = `/company/growth?company_id=5253809&access_token=${accessToken}`;
    const modernaDemoPath = `/company/growth?company_id=30122842&access_token=${accessToken}`;
    const databricksDemoPath = `/company/growth?company_id=7029074&access_token=${accessToken}`;

    const trackNavLinkClick = (label) => {
        // Check if gtag function is available
        if (typeof window.gtag === 'function') {
            console.log('DemoHomePage: Tracking navlink click event with label: ' + label);
            let gAnalyticsId = getGoogleAnalyticsId();
            window.gtag('config', gAnalyticsId, {
                'user_id': accessToken
            });
            window.gtag('set', 'user_properties', {
                'access_token': accessToken
            });
            window.gtag('event', 'navlink_click', {
                'event_category': 'navigation',
                'event_label': label
            });
        }
    };

    useEffect(() => {
        try {
            const getDemoHomePageData = async() => {
                let apiUrl = getApiServrUrl();
                apiUrl += process.env.REACT_APP_API_LVR_DEMO_ENDPOINT;
                console.log('DemoHomePage: Fetching lvr demo homepage data from ' + apiUrl + '...');
               
                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    },
                };
        
                try {
                    const response = await axios.post(apiUrl, {}, config);
                    console.log(response);
                }
                catch(err) {
                    if (err.response && err.response.status == 401 || err.response.status === 403) {
                        setIsUnAuthorized(true);
                    }
                    console.log(err);
                }
            };

            getDemoHomePageData();
        }
        catch (err) {
            console.log(err);
            if (err.response && err.response.status == 401 || err.response.status === 403) {
                setIsUnAuthorized(true);
            }
        }

    }, []);

    if (isUnAuthorized) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. Please contact the admin for more details.</p>
            </div>
        );
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', textAlign: 'center'}}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', backgroundColor: "#f5f5f5", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"}}>
                <a href="https://telemetryllc.com" target="_blank" rel="noopener noreferrer">
                    <img src={process.env.PUBLIC_URL + '/telemetry-logo-black.png'} style={{ height: '50px', width: 'auto'}}/>
                </a>
            </div>
            <br />
            <br />
            <header>
                <h1>Welcome to Telemetry LVR Demo</h1>
            </header>
            <br />
            <br />
            <div style={{ flex: '1'}}>
                <p>
                    Please click on any of the links below for company insights.
                </p>
                <br/>
                <br/>
                <br/>
                <nav>
                    <NavLink 
                        to={metaDemoPath} 
                        className="nav-link" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        onClick={() => trackNavLinkClick('Demo - Meta Clicked')}
                    >
                        Meta
                    </NavLink>
                    <NavLink 
                        to={modernaDemoPath} 
                        className="nav-link" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        onClick={() => trackNavLinkClick('Demo - Moderna Clicked')}
                    >
                        Moderna
                    </NavLink>
                    <NavLink 
                        to={databricksDemoPath}
                        className="nav-link" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        onClick={() => trackNavLinkClick('Demo - Databricks Clicked')}
                    >
                        Databricks
                    </NavLink>
                </nav>
            </div>
            <footer style={{ backgroundColor: "#f5f5f5", padding: "10px 0", textAlign: 'center'}}>
                © 2023 Telemetry LLC. All rights reserved.
            </footer>
        </div>
    );
};

export default DemoHomePageLayout;