import _ from 'lodash';
import './App.css';
import { NotFoundPage } from './pages/not-found-page';
import { DemoHomePage } from './pages/demo_page';
import { CompanyGrowthPage } from './pages/company_growth_page';
import { CompanyTeamGrowthPage } from './pages/company_team_growth_page';
import { Routes, Route } from 'react-router-dom';
import { CompanyTalentPage } from './pages/company_talent_page';
import { UniverseChartPage } from './pages/universe_page';
import { UniverseCompanyGrowthPage } from './pages/universe_company_growth_page';
import { UniverseCompanyTeamGrowthPage } from './pages/universe_company_team_growth_page';
import { UniverseCompanyTalentPage } from './pages/universe_company_talent_page';
import { CompanyTenurePage } from './pages/company_tenure_page';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getGoogleAnalyticsId } from './utils/utils';

function usePageViews() {
    let location = useLocation();

    useEffect(() => {
        // Assuming gtag is available as a global function
        if (typeof window.gtag === 'function') {
			let gAnalyticsId = getGoogleAnalyticsId();
            window.gtag('config', gAnalyticsId, {
                'page_path': location.pathname + location.search
            });
        }
    }, [location]);
}

function App() {
	usePageViews();
	
	return (
		<div>
			<Routes>
				<Route exact path="/" element={<NotFoundPage />} />
				<Route path="*" element={<NotFoundPage />} />

				<Route path="/home" element={<DemoHomePage />} />

				<Route path="/universe/summary" element={<UniverseChartPage />} />
				<Route path="/universe/company/growth" element={<UniverseCompanyGrowthPage />} />
				<Route path="/universe/company/team/growth" element={<UniverseCompanyTeamGrowthPage />} />
				<Route path="/universe/company/talent" element={<UniverseCompanyTalentPage />} />


				<Route path="/company/growth" element={<CompanyGrowthPage />} />
				<Route path="/company/team/growth" element={<CompanyTeamGrowthPage />} />
				<Route path="/company/talent" element={<CompanyTalentPage />} />
				<Route path="/company/tenure" element={<CompanyTenurePage />} />
				
			</Routes>
		</div>
	);
}

export default App;