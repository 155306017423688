import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import Highcharts from 'highcharts';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { customSort, getApiServrUrl, getLastDateOfMonth, getGoogleAnalyticsId } from '../utils/utils';
import { lineColors } from '../utils/constants';
import { DownloadButton } from './download_button';
import Select from 'react-select';


const CompanyGrowthChartComponent = () => {
    const [data, setData] = useState(null);
    const [universeAvgData, setUniverseAvgData] = useState(null);
    const [additionData, setAdditionData] = useState(null);
    const [attritionData, setAttritionData] = useState(null);
    const [additionAttritionDate, setAdditionAttritionDate] = useState(new Date(process.env.REACT_APP_DATA_VERSION_DATE));
    
    const [geo1Values, setGeo1Values] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState({value: 'All', label: 'All'});

    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isMetaDataLoading, setIsMetaDataLoading] = useState(false);
    const [isAdditionAttritionLoading, setIsAdditionAttritionLoading] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [publicProfileCount, setPublicProfileCount] = useState(0);
    const [isUnAuthorized, setIsUnAuthorized] = useState(false);    
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const universeId = searchParams && searchParams.get('universe_id') || location.state && location.state.universeId;
    const companyId = searchParams && searchParams.get('company_id') || location.state && location.state.companyId;
    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.accessToken;

    const companyGrowthPath = `/company/growth?company_id=${companyId}&access_token=${accessToken}`;
    const teamGrowthPath = `/company/team/growth?company_id=${companyId}&access_token=${accessToken}`;
    const talentFlowPath = `/company/talent?company_id=${companyId}&access_token=${accessToken}`;
    const tenurePath = `/company/tenure?company_id=${companyId}&access_token=${accessToken}`;

    const sortOrder = {
        'owner': 0,
        'executive': 1,
        'vice president': 2,
        'director': 3,
        'manager': 4,
        'individual contributor': 5,
        'assistant': 6,
        'trainee': 7,
        'student / intern': 8,
        'unsure': 9,

        'Executive': 0,
        'Manager / Director': 3,
        'Individual Contributor': 5,
        'Intern': 8,
        'Other': 9,

        // new mappings 2023-03-15
        'pct_executive': 0,
        'pct_advisor_board': 1,
        'pct_leadership': 2,
        'pct_management': 3,
        'pct_individual_contributor': 4,
        'pct_student___intern': 5,
    };

    const getGrowthData = async() => {
        let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_GROWTH_ENDPOINT;
        
        console.log(`CompanyGrowth: Fetching growth data from ${apiUrl}...`);
        let data = {
            "company_ids": [parseInt(companyId)],
        }
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        };
        try {
            const response = await axios.post(apiUrl, data, config);
            if (response.status == 200 && response.data.data.headcount.length !== 0) {
                const jsonData = JSON.parse(response.data.data.headcount);
                setData(jsonData);
                let geo1Values = [... new Set(jsonData.map((item) => item.geo1))];
                let sortedGeo1Values = customSort(geo1Values, ['All', 'North America', 'Europe, Middle East & Africa', 'Latin America', 'Asia/Pacific']);
                setGeo1Values(sortedGeo1Values);
            }
        } catch (err) {
            if (err.response && err.response.status == 401 || err.response.status == 403) {
                setIsUnAuthorized(true);
            }
            console.log(err);
        }
        
        if (universeId !== null && universeId !== undefined) {
            // get universe growth avg data
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_UNIVERSE_GROWTH_AVG_ENDPOINT;
            
            console.log(`UniverseCompanyGrowthAvg: Fetching growth avg data from ${apiUrl}...`);
            let data = {
                "universe_id": `${universeId}`,
            }
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                }
            };
            try {
                const response = await axios.post(apiUrl, data, config);
                if (response.status == 200 && response.data.data.universe_headcount_company_avg.length !== 0) {
                    const jsonData = JSON.parse(response.data.data.universe_headcount_company_avg);
                    setUniverseAvgData(jsonData);

                    console.log('UniverseCompanyGrowthAvg: universeAvgData: ', universeAvgData);
                }
            } catch (err) {
                if (err.response && err.response.status == 401 || err.response.status == 403) {
                    setIsUnAuthorized(true);
                }
                console.log(err);
            }
        }

        setIsPageLoading(false);
    };

    const getAdditionsData = async() => {
        let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_ADDITIONS_ENDPOINT;
        console.log(`CompanyGrowth: Fetching growth additions data from ${apiUrl}...`);
        
        let data = {
            "company_ids": [parseInt(companyId)],
            "year": additionAttritionDate.getUTCFullYear(),
            "month": additionAttritionDate.getUTCMonth() + 1,
        }
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        };

        try {
            setIsAdditionAttritionLoading(true);
            const response = await axios.post(apiUrl, data, config);
            let dateStr =  '';
            dateStr += additionAttritionDate.getUTCFullYear();
            if (additionAttritionDate.getUTCMonth() + 1  < 10) {
                dateStr += '-0' + (additionAttritionDate.getUTCMonth() + 1);
            }
            else {
                dateStr += '-' + (additionAttritionDate.getUTCMonth() + 1);
            }
            if (additionAttritionDate.getUTCDate() < 10) { 
                dateStr += '-0' + additionAttritionDate.getUTCDate();
            }
            else { 
                dateStr += '-' + additionAttritionDate.getUTCDate();
            }
            if (response.status == 200 && response.data.data.headcount_additions[companyId][dateStr] !== undefined) {
                const additionData = response.data.data.headcount_additions[companyId][dateStr];
                const additionDataSorted = additionData.sort((a, b) => {
                    return sortOrder[a.seniority] - sortOrder[b.seniority];
                });
                setAdditionData(additionDataSorted.slice(0, 25));
            }
            else {
                setAdditionData(null);
            }

            
        } catch (err) {
            console.log(err);
        }
    };

    const getAttritionsData = async() => {
        let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_ATTRITIONS_ENDPOINT;
        console.log(`CompanyGrowth: Fetching growth attritions data from ${apiUrl}...`);
        
        let data = {
            "company_ids": [parseInt(companyId)],
            "year": additionAttritionDate.getUTCFullYear(),
            "month": additionAttritionDate.getUTCMonth() + 1,
        }
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        };
        try {
            const response = await axios.post(apiUrl, data, config);
            let dateStr = '';
            dateStr += additionAttritionDate.getUTCFullYear();
            if (additionAttritionDate.getUTCMonth() + 1  < 10) {
                dateStr += '-0' + (additionAttritionDate.getUTCMonth() + 1);
            }
            else {
                dateStr += '-' + (additionAttritionDate.getUTCMonth() + 1);
            }
            if (additionAttritionDate.getUTCDate() < 10) { 
                dateStr += '-0' + additionAttritionDate.getUTCDate();
            }
            else { 
                dateStr += '-' + additionAttritionDate.getUTCDate();
            }
            if (response.status == 200 && response.data.data.headcount_attritions[companyId][dateStr] !== undefined) {
                const attritionData = response.data.data.headcount_attritions[companyId][dateStr];
                const attritionDataSorted = attritionData.sort((a, b) => {
                    return sortOrder[a.seniority] - sortOrder[b.seniority];
                });
                setAttritionData(attritionDataSorted.slice(0, 25));
            }
            else {
                setAttritionData(null);
            }

        } catch (err) {
            console.log(err);
        }
        setIsAdditionAttritionLoading(false);
    };

    function getOptionsGrowth() {
        const filteredData = data.filter((item) => {
            return item.geo1 === selectedRegion.value;
        });
        const displayBenchmark = selectedRegion.value === 'All';
		const ltmHeadcountGrowthData = filteredData.map((item) => {
			return [item.the_date, parseFloat((item.ltm_net_hc_growth * 100).toFixed(1))];
		});
		const ltmEmployeeAdditionData = filteredData.map((item) => {
			return [item.the_date, parseFloat((item.ltm_addition_rate * 100).toFixed(1))];
		});
		const ltmEmployeeAttritionData = filteredData.map((item) => {
			return [item.the_date, parseFloat((item.ltm_attrition_rate * 100).toFixed(1))];
		});
        const ltmEmployeeGrowthProductivityData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.ltm_growth_productivity * 100).toFixed(1))];
        });
        const ltmAvgNetHCGrowthBenchmarkData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.avg_net_hc_growth_prev_12_mo_benchmark * 100).toFixed(1))];
        });
        const ltmAvgAttritionRateBenchmarkData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.avg_attrition_rate_prev_12_mo_benchmark * 100).toFixed(1))];
        });
        const ltmAvgAdditionRateBenchmarkData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.avg_addition_rate_prev_12_mo_benchmark * 100).toFixed(1))];
        });
        const ltmAvgGrowthProductivityBenchmarkData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.avg_growth_prod_prev_12_mo_benchmark * 100).toFixed(1))];
        });

        const dataSeries = [
            {
                type: 'spline',
                name: 'Net Headcount Growth (Last 12 months)',
                data: ltmHeadcountGrowthData,
                color: lineColors['Net Headcount'],
            },
            {
                type: 'spline',
                name: 'Hiring Rate (Last 12 months)',
                data: ltmEmployeeAdditionData,
                color: lineColors['Hiring'],
            },
            {
                type: 'spline',
                name: 'Attrition Rate (Last 12 months)',
                data: ltmEmployeeAttritionData,
                color: lineColors['Attrition'],
            },
            {
                type: 'spline',
                name: 'Growth Productivity (Last 12 months)',
                data: ltmEmployeeGrowthProductivityData,
                color: lineColors['Growth Productivity'],
            },
        ];
        if (displayBenchmark) {
            dataSeries.push(
                {
                    type: 'spline',
                    name: 'Benchmark: Avg Net HC Growth',
                    data: ltmAvgNetHCGrowthBenchmarkData,
                    color: lineColors['Net Headcount'],
                    dashStyle: 'Dash',
                },
                {
                    type: 'spline',
                    name: 'Benchmark: Avg Hiring Rate',
                    data: ltmAvgAdditionRateBenchmarkData,
                    color: lineColors['Addition'],
                    dashStyle: 'Dash',
                },
                {
                    type: 'spline',
                    name: 'Benchmark: Avg Attrition Rate',
                    data: ltmAvgAttritionRateBenchmarkData,
                    color: lineColors['Attrition'],
                    dashStyle: 'Dash',
                },
                {
                    type: 'spline',
                    name: 'Benchmark: Avg Growth Productivity',
                    data: ltmAvgGrowthProductivityBenchmarkData,
                    color: lineColors['Growth Productivity'],
                    dashStyle: 'Dash',
                },
            );
        }

        if (universeAvgData !== null) {
            const filteredUniverseAvgData = universeAvgData.filter((item) => {
                return item.geo1 === selectedRegion.value;
            });
            const ltmAvgHeadcountGrowthData = filteredUniverseAvgData.map((item) => {
                return [item.the_date, parseFloat((item.avg_ltm_net_hc_growth * 100).toFixed(1))];
            });
            const ltmAvgEmployeeAdditionData = filteredUniverseAvgData.map((item) => {
                return [item.the_date, parseFloat((item.avg_ltm_addition_rate * 100).toFixed(1))];
            });
            const ltmAvgEmployeeAttritionData = filteredUniverseAvgData.map((item) => {
                return [item.the_date, parseFloat((item.avg_ltm_attrition_rate * 100).toFixed(1))];
            });
            const ltmAvgEmployeeGrowthProductivityData = filteredUniverseAvgData.map((item) => {
                return [item.the_date, parseFloat((item.avg_ltm_growth_productivity * 100).toFixed(1))];
            });

            dataSeries.push(
                {
                    type: 'spline',
                    name: 'Net Headcount Growth (Last 12 months) - Peer Avg',
                    data: ltmAvgHeadcountGrowthData,
                    color: lineColors['Net Headcount'],
                    dashStyle: 'Dot',
                },
                {
                    type: 'spline',
                    name: 'Hiring Rate (Last 12 months) - Peer Avg',
                    data: ltmAvgEmployeeAdditionData,
                    color: lineColors['Hiring'],
                    dashStyle: 'Dot',
                },
                {
                    type: 'spline',
                    name: 'Attrition Rate (Last 12 months) - Peer Avg',
                    data: ltmAvgEmployeeAttritionData,
                    color: lineColors['Attrition'],
                    dashStyle: 'Dot',
                },
                {
                    type: 'spline',
                    name: 'Growth Productivity (Last 12 months) - Peer Avg',
                    data: ltmAvgEmployeeGrowthProductivityData,
                    color: lineColors['Growth Productivity'],
                },
            );

        }


		return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: '33.3%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Headcount Metrics',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
			yAxis: {
				title: {
					text: 'Rate (Last 12 months)'
				},
				labels: {
					format: '{value}%'
				}
			},
			legend: {
				enabled: true,
			},
			
            series: dataSeries,

			credits: {
				enabled: false
			},
            tooltip: {
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                shared: true,
                crosshairs: true, 
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                let date = new Date(event.point.x);
                                let dateStr = '' + date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
                                setAdditionAttritionDate(new Date(dateStr));
                            }
                        }
                    },
                    events: {
                        afterAnimate: function() {
                            if (!displayBenchmark) {
                                let chart = this.chart;
                                chart.renderer.text('Benchmark data not available', 1100, 25)
                                .css({
                                    fontSize: '14px',
                                    color: 'gray',
                                })
                                .add();
                            }
                        }
                    }
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};
		
	};

    function getOptionsTotalHeadcount() {
        const filteredData = data.filter((item) => {
            return item.geo1 === selectedRegion.value;
        });
        const totalHeadcountData = filteredData.map((item) => {
            return [item.the_date, item.total_headcount];
        });
        const additionData = filteredData.map((item) => {
            let growthPct = item.new_headcount/item.total_headcount;
            return [item.the_date, parseFloat((growthPct * 100).toFixed(1))];
        });
        const attritionData = filteredData.map((item) => {
            let attritionPct = item.lost_headcount/item.total_headcount;
            return [item.the_date, parseFloat((-attritionPct * 100).toFixed(1))];
        });
        return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: '33.3%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
            tooltip: {
                shared: true,
            },
			title: {
				text: 'Total Headcount (Public Profiles)',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
			yAxis: [
                {
				    title: {
					    text: 'Total Headcount (Public Profiles)'
				    },
                    height: '50%',
                    lineWidth: 2,
			    },
                {
                    title: {
                        text: 'Monthly Hiring and Attritions',
                    },
                    top: '55%',
                    height: '45%',
                    offset: 0,
                    lineWidth: 2,
                    labels: {
                        format: '{value}%'
                    },
                },
            ],
			legend: {
				enabled: true,
			},
			
			series: [
				{
					type: 'column',
					name: 'Total Heacount (Public Profiles)',
					data: totalHeadcountData,
                    color: lineColors['Total Headcount'],
                    pointWidth: 10,
                    tooltip: {
                        // format tooltip to show commas in numbers
                        pointFormatter: function() {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' + Highcharts.numberFormat(this.y, 0, undefined, ',') + '</b><br/>';
                        }
                    },
                    opacity: 0.8,
				},
                {  
                    type: 'column',
                    name: 'Hiring',
                    data: additionData,
                    yAxis: 1,
                    color: lineColors['Hiring'],
                    pointWidth: 10,
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                    },
                },
                {
                    type: 'column',
                    name: 'Attrition',
                    data: attritionData,
                    yAxis: 1,
                    color: lineColors['Attrition'],
                    pointWidth: 10,
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                    },
                },
			],

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};
    };

    function getOptionsGenderHeadcount() {
        const filteredData = data.filter((item) => {
            return item.geo1 === selectedRegion.value;
        });
        const ltmCompanyPctFemaleData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.company_hc_total_avg_f_prob * 100).toFixed(1))];
        });
        const ltmHiringPctFemaleData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.company_hc_new_avg_f_prob_prev_12_mo * 100).toFixed(1))];
        });
        const ltmAttritionPctFemaleData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.company_hc_lost_avg_f_prob_prev_12_mo * 100).toFixed(1))]; 
        });
        const publicProfileData = filteredData.map((item) => {
            return [item.the_date, item.total_headcount];
        });

        return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: '33.3%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Gender Probability (Public Profiles)',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime'
			},
			yAxis: [
                {
                    title: {
                        text: 'Percentage Female'
                    },
                    labels: {
                        format: '{value}%'
                    },
			    },
                {   
                    gridLineWidth: 0,
                    title: {
                        text: 'Total Headcount (Public Profiles)'
                    },
                    opposite: true,
                    labels: {
                        formatter: function() {
                            if (this.value >= 1000) {
                                return (this.value/1000) + 'k';
                            }
                            else if ((this.value >= 1000000)) {
                                return (this.value/1000000) + 'M';
                            }
                            return this.value;
                        }
                    },
                    //min: 0,
                },
            ],
			legend: {
				enabled: true,
			},

			tooltip: {
                shared: true,
            },

			series: [
				{
					type: 'column',
					name: 'Public Profiles',
					data: publicProfileData,
                    yAxis: 1,
                    color: lineColors['Public Profiles'],
                    pointWidth: 10,
                    opacity: 0.8,
                    zoomType: 'xy',
                    tooltip: {
                        // format tooltip to show commas in numbers
                        pointFormatter: function() {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' + Highcharts.numberFormat(this.y, 0, undefined, ',') + '</b><br/>';
                        }
                    },
				},
                {
                    type: 'spline',
                    name: 'Pct Female, Company Avg',
                    data: ltmCompanyPctFemaleData,
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                    },
                },
                {
                    type: 'spline',
                    name: 'Pct Female, New Hires (Last 12 months)',
                    data: ltmHiringPctFemaleData,
                    color: lineColors['Hiring'],
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                    },
                },
                {
                    type: 'spline',
                    name: 'Pct Female, Attrits (Last 12 months)',
                    data: ltmAttritionPctFemaleData,
                    color: lineColors['Attrition'],
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                    },
                },
			],

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};
    };

    useEffect(() => {
        try {
            setIsMetaDataLoading(true);
            const getCompanyMetaData = async() => {
                let apiUrl = getApiServrUrl();
                apiUrl += process.env.REACT_APP_API_METADATA_ENDPOINT;
                console.log('CompanyGrowth: Fetching company metadata from ' + apiUrl + '...');
                let data = {
                    "company_ids": [parseInt(companyId)],
                };
        
                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    },
                };
        
                try {
                    const response = await axios.post(apiUrl, data, config);
                    console.log(response);
                    if(response.status === 200 && response.data.data.metadata.length !== 0) {
                        const metaData = JSON.parse(response.data.data.metadata);
                        console.log(metaData);
                        setCompanyName(metaData[0].name);
                        if (metaData[0].num_valid_public_profiles) {
                            setPublicProfileCount(metaData[0].num_valid_public_profiles);
                        }
                    }
                }
                catch(err) {
                    if (err.response && err.response.status == 401 || err.response.status === 403) {
                        setIsUnAuthorized(true);
                    }
                    console.log(err);
                }
                
                setIsMetaDataLoading(false);
            };
            getCompanyMetaData();
        }
        catch(err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        try {
            if (publicProfileCount !== 0) {
                setIsPageLoading(true);
                getGrowthData();
                getAdditionsData();
                getAttritionsData();
            }
        }
        catch(err) {
            console.log(err);
        }
    }, [publicProfileCount]);

    useEffect(() => {
        try {
            if (publicProfileCount !== 0) {
                getAdditionsData();
                getAttritionsData();
            }
        }
        catch(err) {
            console.log(err);
        }
    }, [additionAttritionDate]);

    useEffect(() => {
        try {
            if (data !== null) {
                let growthChartOptions = getOptionsGrowth();
                Highcharts.chart('growth-chart-container', growthChartOptions);

                let totalHeadcountOptions = getOptionsTotalHeadcount();
                Highcharts.chart('total-headcount-chart-container', totalHeadcountOptions);

                let companyGenderOptions = getOptionsGenderHeadcount();
                Highcharts.chart('company-gender-chart-container', companyGenderOptions);
            }
        }
        catch(err) {
            console.log(err);
        }
    }, [data, universeAvgData, isUnAuthorized, companyName, publicProfileCount, selectedRegion]);

    const handleGeo1ChangeWithSelect = (selectedOption) => {
        if (selectedOption === null || selectedOption.length === 0) {
            console.warn('Atleast one region must be selected');
            return;
        }
        console.log('selectedRegions: ', selectedOption);
        setSelectedRegion(selectedOption);
    };

    const customStyles = {
        control: (provided) => ({
        ...provided,
        width: 200,
        }),
        menu: (provided) => ({
        ...provided,
        width: 200,
        }),
    };

    const trackNavLinkClick = (label) => {
        // Check if gtag function is available
        if (typeof window.gtag === 'function') {
            console.log('CompanyGrowthPage: Tracking navlink click event with label: ' + label);
            const gAnalyticsId = getGoogleAnalyticsId();
            window.gtag('config', gAnalyticsId, {
                'user_id': accessToken
            });
            window.gtag('set', 'user_properties', {
                'token': accessToken
            });
            window.gtag('event', 'navlink_click', {
                'event_category': 'navigation',
                'event_label': label
            });
        }
    };


    if (isUnAuthorized) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. Please contact your admin for more details.</p>
            </div>
        );
    }
    else if ( publicProfileCount === 0) {
        if (isMetaDataLoading) {
            return (
                <div style={{ textAlign: "center" }} >
                    <ClipLoader />
                </div>
            );
        }
        else {
            return (
                <div style={{ textAlign: "center" }} >
                    <h1>No data</h1>
                    <p>Sorry, but the asset data you're requesting is currently unavailable.</p>
                </div>
            );
        }
    }
    else {
        return (
            <div>
                <div style={{display: 'flex', justifyContent: 'flex-start', padding: '10px'}}>
                    <NavLink 
                        style={{fontWeight: 'bold', color: 'blue', padding: '10px', backgroundColor: 'lightgray', borderRadius: '20px'}}
                        to={companyGrowthPath}
                        state={{companyId: companyId, companyName: companyName, accessToken: accessToken}}
                        onClick={() => trackNavLinkClick(`Company Growth - ${companyName} clicked`)}
                    > Company
                    </NavLink>
                    <div style={{ padding: '10px'}}></div>
                    <NavLink 
                        style={{fontWeight: 'bold', color: 'blue', padding: '10px', backgroundColor: 'lightgray', borderRadius: '20px'}}
                        to={teamGrowthPath}
                        state={{companyId: companyId, accessToken: accessToken, companyName: companyName}}
                        onClick={() => trackNavLinkClick(`Teams - ${companyName} clicked`)}
                    > Teams 
                    </NavLink>
                    <div style={{ padding: '10px'}}></div>
                    <NavLink 
                        style={{fontWeight: 'bold', color: 'blue', padding: '10px', backgroundColor: 'lightgray', borderRadius: '20px'}}
                        to={talentFlowPath}
                        state={{companyId: companyId, accessToken: accessToken, companyName: companyName}}
                        onClick={() => trackNavLinkClick(`Talent Flow - ${companyName} clicked`)}
                    > Talent Flow
                    </NavLink>
                    <div style={{ padding: '10px'}}></div>
                    <NavLink 
                        style={{fontWeight: 'bold', color: 'blue', padding: '10px', backgroundColor: 'lightgray', borderRadius: '20px'}}
                        to={tenurePath}
                        state={{companyId: companyId, accessToken: accessToken, companyName: companyName}}
                        onClick={() => trackNavLinkClick(`Tenure - ${companyName} clicked`)}
                    > Tenure
                    </NavLink>
                </div>
                <div style={{textAlign: 'center'}}>
                    <h1 style={{marginTop: '0px', marginBottom: '10px'}}>
                        Company Growth: {companyName}
                    </h1>
                </div>
                { isPageLoading ? (
                    <div style={{ textAlign: "center" }} ><ClipLoader/> </div>
                ) : (
                <div>
                <div className='checkbox-group-container'> 
                    <div className='checkbox-container'>
                        <label htmlFor='regions'>Region:</label>
                        &nbsp;&nbsp;
                        <Select
                            name="regions"
                            options={geo1Values.map((value) => ({value: value, label: value}))}
                            value={selectedRegion}
                            onChange={handleGeo1ChangeWithSelect}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <br/>
                <div className='chart-container'>
                    <div className="chart-container-left">
                        <div className="chart-container-left-child" id="growth-chart-container"></div>
                        <br/>
                        <div className='chart-container-left-child' id="company-gender-chart-container"></div>
                        <br/>
                        {/* <div className='chart-container-left-child' id="addition-attrition-chart-container"></div> */}
                        <div className="chart-container-left-child" id="total-headcount-chart-container"></div>
                    </div>
                    <div className='chart-container-right'>
                        <div className = 'chart-container-right-child' id="addition-container">
                            <p> Click on the Headcount Metrics chart to list new hires and attritions for a particular time</p>
                            <h3> New Hires ({additionAttritionDate.toLocaleString('default',  {month: 'long', year: 'numeric'})})</h3>
                            { isAdditionAttritionLoading ? (
                                <ClipLoader/>
                            ) : additionData ? (
                                
                                <ul>
                                    { 
                                        additionData.map((item, index) => (
                                            <li key={index}>
                                                <a href= {item.linkedin_bug} rel="noreferrer" target="_blank">{item.title}</a>
                                            </li>
                                    ))
                                    }
                                </ul>
                            ) : (
                                <p> No data </p>
                            )}
                        </div>
                        <div className = 'chart-container-right-child' id="attrition-container">
                            <h3> Recent Attritions ({additionAttritionDate.toLocaleString('default',  {month: 'long', year: 'numeric'})})</h3>
                            { isAdditionAttritionLoading ? (
                                <ClipLoader/>
                            ) : attritionData ? (
                                <ul>
                                    { 
                                        attritionData.map((item, index) => (
                                            <li key={index}>
                                                <a href= {item.linkedin_bug} rel="noreferrer" target="_blank">{item.title}</a>
                                            </li>
                                        ))
                                        
                                    }
                                </ul>
                            ) : (
                                <p> No data </p>
                            )}
                        </div>
                    </div>
                </div>
                <div style={{ margin:'0 auto', textAlign: 'center'}}>
                    <DownloadButton 
                        data={data} 
                        companyName={companyName} 
                        buttonText={ 'Download Growth Data' }
                        fileName={ `${companyName}_growth_data.csv` }
                        companyGrowth = {true}
                    />
                </div>
                <div style={{ margin: '10px'}}>
                    Notes:
                    <ul>
                        <li>Data as of {getLastDateOfMonth(process.env.REACT_APP_DATA_VERSION_DATE).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</li>
                        <li>All data and analysis is based on publicly available profiles</li>
                    </ul>
                </div>
                </div> )}
            </div>
        );
    }   
};

export default CompanyGrowthChartComponent;
