import { PageLayout } from "../components/page-layout";
import UniverseCompanyTeamGrowthChartComponent from "../components/universe_company_teams_growth_chart";
import UniverseCompanyTeamGrowthChartComponentNew from "../components/universe_company_teams_growth_chart_refactored";

export const UniverseCompanyTeamGrowthPage = () => {
    return (
        <PageLayout>
            <UniverseCompanyTeamGrowthChartComponentNew />
        </PageLayout>
    );
};
