import React from "react";
import { NavBar } from "./navigation/nav-bar";

export const PageLayout = ({ children }) => {
  return (
    <div className="page-layout">
      {/* <NavBar /> */}
      <div className="page-layout__content">{children}</div>
      {/* <PageFooter /> */}
      {/* <div style={{ margin: '10px', alignContent: 'center', textAlign: 'bottom' }}>
        <footer>
          © 2020-2023 Telemetry LLC. All rights reserved.
        </footer>
      </div> */}
    </div>
  );
};
