import { useSearchParams, useLocation } from "react-router-dom";
import CompanyGrowthChartComponent from "../components/company_growth_chart"
import { PageLayout } from "../components/page-layout"

export const CompanyGrowthPage = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const demoCompanyIds = [5253809, 30122842, 7029074];
    const companyId = searchParams && searchParams.get('company_id') || location.state && location.state.companyId;
    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.accessToken;

    if (companyId && !demoCompanyIds.includes(parseInt(companyId)) && accessToken !== 'internal_access_token') {
        return (
            <div style={{ textAlign: "center" }} >
                <h1>No data</h1>
                <p>Sorry, but the asset data you're requesting is currently unavailable in the demo.</p>
            </div>
        );
    }

    return (
        <PageLayout>
            <CompanyGrowthChartComponent />
        </PageLayout>
    );
};