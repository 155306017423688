import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useParams, useSearchParams } from 'react-router-dom';
import Highcharts from 'highcharts';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { customSort, getApiServrUrl } from '../utils/utils';
import { lineColors } from '../utils/constants';
import { DownloadButton } from './download_button';


const UniverseCompanyGrowthChartComponent = () => {
    const [data, setData] = useState(null);
    const [universeAvgData, setUniverseAvgData] = useState(null);
    const [additionData, setAdditionData] = useState(null);
    const [attritionData, setAttritionData] = useState(null);
    const [additionAttritionDate, setAdditionAttritionDate] = useState(new Date(process.env.REACT_APP_DATA_VERSION_DATE));
    const [geo1Values, setGeo1Values] = useState([]);
    const [selectedGeo1, setSelectedGeo1] = useState('option1');
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isMetaDataLoading, setIsMetaDataLoading] = useState(false);
    const [isAdditionAttritionLoading, setIsAdditionAttritionLoading] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [publicProfileCount, setPublicProfileCount] = useState(0);
    const [isUnAuthorized, setIsUnAuthorized] = useState(false);    
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const universeId = searchParams && searchParams.get('universe_id') || location.state && location.state.universeId;
    const companyId = searchParams && searchParams.get('company_id') || location.state && location.state.companyId;
    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.accessToken;

    const universeSummaryPath = `/universe/summary?universe_id=${universeId}&access_token=${accessToken}`;
    const teamGrowthPath = `/universe/company/team/growth?universe_id=${universeId}&company_id=${companyId}&access_token=${accessToken}`;
    const talentFlowPath = `/universe/company/talent?universe_id=${universeId}&company_id=${companyId}&access_token=${accessToken}`;
    
    const sortOrder = {
        'owner': 0,
        'executive': 1,
        'vice president': 2,
        'director': 3,
        'manager': 4,
        'individual contributor': 5,
        'assistant': 6,
        'trainee': 7,
        'student / intern': 8,
        'unsure': 9,

        'Executive': 0,
        'Manager / Director': 3,
        'Individual Contributor': 5,
        'Intern': 8,
        'Other': 9,

        // new mappings 2023-03-15
        'pct_executive': 0,
        'pct_advisor_board': 1,
        'pct_leadership': 2,
        'pct_management': 3,
        'pct_individual_contributor': 4,
        'pct_student___intern': 5,
    };

    const getGrowthData = async() => {
        let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_GROWTH_ENDPOINT;
        
        console.log(`CompanyGrowth: Fetching growth data from ${apiUrl}...`);
        let data = {
            "company_ids": [parseInt(companyId)],
        }
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        };
            try {
                const response = await axios.post(apiUrl, data, config);
                if (response.status == 200 && response.data.data.headcount.length !== 0) {
                    const jsonData = JSON.parse(response.data.data.headcount);
                    setData(jsonData);
                    // setCompanyName(jsonData[0]['company_shorthand_name']);
                    
                    let geo1Values = [... new Set(jsonData.map((item) => item.geo1))];
                    let sortedGeo1Values = customSort(geo1Values, ['All', 'North America', 'Europe, Middle East & Africa', 'Latin America', 'Asia/Pacific']);
                    setGeo1Values(sortedGeo1Values);
                    setSelectedGeo1("All");
                }
                // setIsPageLoading(false);
            } catch (err) {
                if (err.response && err.response.status == 401 || err.response.status == 403) {
                    setIsUnAuthorized(true);
                }
                console.log(err);
            }

        //setIsPageLoading(false);
    };

    const getUniverseGrowthAvgData = async() => {
        let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_UNIVERSE_GROWTH_AVG_ENDPOINT;
        
        console.log(`UniverseCompanyGrowthAvg: Fetching growth avg data from ${apiUrl}...`);
        let data = {
            //"universe_id": parseInt(universeId),
            "universe_id": `${universeId}`,
        }
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        };
            try {
                const response = await axios.post(apiUrl, data, config);
                if (response.status == 200 && response.data.data.universe_headcount_company_avg.length !== 0) {
                    const jsonData = JSON.parse(response.data.data.universe_headcount_company_avg);
                    setUniverseAvgData(jsonData);
                }
            } catch (err) {
                if (err.response && err.response.status == 401 || err.response.status == 403) {
                    setIsUnAuthorized(true);
                }
                console.log(err);
            }

        setIsPageLoading(false);
    };

    const getAdditionsData = async() => {
        let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_ADDITIONS_ENDPOINT;
        console.log(`CompanyGrowth: Fetching growth additions data from ${apiUrl}...`);
        
        let data = {
            // "company_ids": [919192, , 926354, 950648, 907611, ],
            "company_ids": [parseInt(companyId)],
            "year": additionAttritionDate.getUTCFullYear(),
            "month": additionAttritionDate.getUTCMonth() + 1,
        }
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        };

        try {
            setIsAdditionAttritionLoading(true);
            const response = await axios.post(apiUrl, data, config);
            let dateStr =  '';
            dateStr += additionAttritionDate.getUTCFullYear();
            if (additionAttritionDate.getUTCMonth() + 1  < 10) {
                dateStr += '-0' + (additionAttritionDate.getUTCMonth() + 1);
            }
            else {
                dateStr += '-' + (additionAttritionDate.getUTCMonth() + 1);
            }
            if (additionAttritionDate.getUTCDate() < 10) { 
                dateStr += '-0' + additionAttritionDate.getUTCDate();
            }
            else { 
                dateStr += '-' + additionAttritionDate.getUTCDate();
            }
            if (response.status == 200 && response.data.data.headcount_additions[companyId][dateStr] !== undefined) {
                const additionData = response.data.data.headcount_additions[companyId][dateStr];
                const additionDataSorted = additionData.sort((a, b) => {
                    return sortOrder[a.seniority] - sortOrder[b.seniority];
                });
                setAdditionData(additionDataSorted.slice(0, 25));
            }
            else {
                setAdditionData(null);
            }

            
        } catch (err) {
            console.log(err);
        }
    };

    const getAttritionsData = async() => {
        let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_ATTRITIONS_ENDPOINT;
        console.log(`CompanyGrowth: Fetching growth attritions data from ${apiUrl}...`);
        
        let data = {
            // "company_ids": [919192, , 926354, 950648, 907611, ],
            "company_ids": [parseInt(companyId)],
            "year": additionAttritionDate.getUTCFullYear(),
            "month": additionAttritionDate.getUTCMonth() + 1,
        }
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        };
        try {
            const response = await axios.post(apiUrl, data, config);
            let dateStr = '';
            dateStr += additionAttritionDate.getUTCFullYear();
            if (additionAttritionDate.getUTCMonth() + 1  < 10) {
                dateStr += '-0' + (additionAttritionDate.getUTCMonth() + 1);
            }
            else {
                dateStr += '-' + (additionAttritionDate.getUTCMonth() + 1);
            }
            if (additionAttritionDate.getUTCDate() < 10) { 
                dateStr += '-0' + additionAttritionDate.getUTCDate();
            }
            else { 
                dateStr += '-' + additionAttritionDate.getUTCDate();
            }
            if (response.status == 200 && response.data.data.headcount_attritions[companyId][dateStr] !== undefined) {
                const attritionData = response.data.data.headcount_attritions[companyId][dateStr];
                const attritionDataSorted = attritionData.sort((a, b) => {
                    return sortOrder[a.seniority] - sortOrder[b.seniority];
                });
                setAttritionData(attritionDataSorted.slice(0, 25));
            }
            else {
                setAttritionData(null);
            }

        } catch (err) {
            console.log(err);
        }
        setIsAdditionAttritionLoading(false);
    };

    function getOptionsGrowth() {
        const filteredData = data.filter((item) => {
            return item.geo1 === selectedGeo1;
        });
		const ltmHeadcountGrowthData = filteredData.map((item) => {
			return [item.the_date, parseFloat((item.ltm_net_hc_growth * 100).toFixed(2))];
		});
		const ltmEmployeeAdditionData = filteredData.map((item) => {
			return [item.the_date, parseFloat((item.ltm_addition_rate * 100).toFixed(2))];
		});
		const ltmEmployeeAttritionData = filteredData.map((item) => {
			return [item.the_date, parseFloat((item.ltm_attrition_rate * 100).toFixed(2))];
		});
        const ltmEmployeeGrowthProductivityData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.ltm_growth_productivity * 100).toFixed(2))];
        });
        const ltmAvgNetHCGrowthBenchmarkData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.avg_net_hc_growth_prev_12_mo_benchmark * 100).toFixed(2))];
        });
        const ltmAvgAttritionRateBenchmarkData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.avg_attrition_rate_prev_12_mo_benchmark * 100).toFixed(2))];
        });
        const ltmAvgAdditionRateBenchmarkData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.avg_addition_rate_prev_12_mo_benchmark * 100).toFixed(2))];
        });
        const ltmAvgGrowthProductivityBenchmarkData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.avg_growth_prod_prev_12_mo_benchmark * 100).toFixed(2))];
        });

        const filteredUniverseAvgData = universeAvgData.filter((item) => {
            return item.geo1 === selectedGeo1;
        });
        const ltmAvgHeadcountGrowthData = filteredUniverseAvgData.map((item) => {
            return [item.the_date, parseFloat((item.avg_ltm_net_hc_growth * 100).toFixed(2))];
        });
        const ltmAvgEmployeeAdditionData = filteredUniverseAvgData.map((item) => {
            return [item.the_date, parseFloat((item.avg_ltm_addition_rate * 100).toFixed(2))];
        });
        const ltmAvgEmployeeAttritionData = filteredUniverseAvgData.map((item) => {
            return [item.the_date, parseFloat((item.avg_ltm_attrition_rate * 100).toFixed(2))];
        });
        const ltmAvgEmployeeGrowthProductivityData = filteredUniverseAvgData.map((item) => {
            return [item.the_date, parseFloat((item.avg_ltm_growth_productivity * 100).toFixed(2))];
        });

		return {
			chart: {
				zoomType: 'x',
                type: 'spline',
                height: '33.3%',
			},
			title: {
				text: 'Headcount Growth',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime'
			},
			yAxis: {
				title: {
					text: 'LTM Net Headcount Growth'
				},
				labels: {
					format: '{value}%'
				}
			},
			legend: {
				enabled: true,
			},
			
			series: [
				{
					type: 'spline',
					name: 'LTM Net Headcount Growth',
					data: ltmHeadcountGrowthData,
                    color: lineColors['Net Headcount'],
				},
				{
					type: 'spline',
					name: 'LTM Employee Hiring',
					data: ltmEmployeeAdditionData,
                    color: lineColors['Hiring'],
				},
				{
					type: 'spline',
					name: 'LTM Employee Attrition',
					data: ltmEmployeeAttritionData,
                    color: lineColors['Attrition'],
				},
                {
                    type: 'spline',
                    name: 'LTM Employee Growth Productivity',
                    data: ltmEmployeeGrowthProductivityData,
                    color: lineColors['Growth Productivity'],
                },
                {
					type: 'spline',
					name: 'Peer Avg LTM Net Headcount Growth',
					data: ltmAvgHeadcountGrowthData,
                    color: lineColors['Net Headcount'],
                    dashStyle: 'ShortDash',
                    visible: false,
				},
				{
					type: 'spline',
					name: 'Peer Avg LTM Employee Hiring',
					data: ltmAvgEmployeeAdditionData,
                    color: lineColors['Hiring'],
                    dashStyle: 'ShortDash',
                    visible: false,
				},
				{
					type: 'spline',
					name: 'Peer Avg LTM Employee Attrition',
					data: ltmAvgEmployeeAttritionData,
                    color: lineColors['Attrition'],
                    dashStyle: 'ShortDash',
                    visible: false,
				},
                {
                    type: 'spline',
                    name: 'Peer Avg LTM Employee Growth Productivity',
                    data: ltmAvgEmployeeGrowthProductivityData,
                    color: lineColors['Growth Productivity'],
                    dashStyle: 'ShortDash',
                    visible: false,
                },
                {
                    type: 'spline',
                    name: 'Benchmark: Avg Net HC Growth',
                    data: ltmAvgNetHCGrowthBenchmarkData,
                    color: lineColors['Net Headcount'],
                    dashStyle: 'Dash',
                },
                {
                    type: 'spline',
                    name: 'Benchmark: Avg Attrition Rate',
                    data: ltmAvgAttritionRateBenchmarkData,
                    color: lineColors['Attrition'],
                    dashStyle: 'Dash',
                },
                {
                    type: 'spline',
                    name: 'Benchmark: Avg Addition Rate',
                    data: ltmAvgAdditionRateBenchmarkData,
                    color: lineColors['Addition'],
                    dashStyle: 'Dash',
                },
                {
                    type: 'spline',
                    name: 'Benchmark: Avg Growth Productivity',
                    data: ltmAvgGrowthProductivityBenchmarkData,
                    color: lineColors['Growth Productivity'],
                    dashStyle: 'Dash',
                },
			],

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                let date = new Date(event.point.x);
                                let dateStr = '' + date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
                                setAdditionAttritionDate(new Date(dateStr));
                            }
                        }
                    }
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};
		
	};

    function getOptionsTotalHeadcount() {
        const filteredData = data.filter((item) => {
            return item.geo1 === selectedGeo1;
        });
        const totalHeadcountData = filteredData.map((item) => {
            return [item.the_date, item.total_headcount];
        });
        return {
			chart: {
				zoomType: 'x',
                type: 'spline',
                height: '33.3%',
			},
			title: {
				text: 'Total Headcount (Public Profiles)',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime'
			},
			yAxis: {
				title: {
					text: 'Total Public Profile Headcount'
				},
			},
			legend: {
				enabled: true,
			},
			
			series: [
				{
					type: 'spline',
					name: 'Headcount',
					data: totalHeadcountData,
				},
			],

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};
    };

    function getOptionsGenderHeadcount() {
        const filteredData = data.filter((item) => {
            return item.geo1 === selectedGeo1;
        });
        const ltmCompanyPctFemaleData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.company_hc_total_avg_f_prob_prev_12_mo * 100).toFixed(2))];
        });
        const ltmHiringPctFemaleData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.company_hc_new_avg_f_prob_prev_12_mo * 100).toFixed(2))];
        });
        const ltmAttritionPctFemaleData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.company_hc_lost_avg_f_prob_prev_12_mo * 100).toFixed(2))]; 
        });
        const publicProfileData = filteredData.map((item) => {
            return [item.the_date, item.company_hc_total_num_gender_prob];
        });

        return {
			chart: {
				zoomType: 'x',
                type: 'spline',
                height: '33.3%',
			},
			title: {
				text: 'Gender Probability (Public Profiles)',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime'
			},
			yAxis: [
                {
                    title: {
                        text: 'Percentage Female'
                    },
                    labels: {
                        format: '{value}%'
                    },
                    min: 0,
			    },
                {   
                    gridLineWidth: 0,
                    title: {
                        text: 'Num of Public Profiles Classified'
                    },
                    opposite: true,
                    labels: {
                        format: '{value}',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        },
                    },
                    min: 0,
                },
            ],
			legend: {
				enabled: true,
			},

			tooltip: {
                shared: true,
            },

			series: [
				{
					type: 'spline',
					name: 'Public Profiles',
					data: publicProfileData,
                    yAxis: 1,
				},
                {
                    type: 'spline',
                    name: 'LTM Company Percentage Female',
                    data: ltmCompanyPctFemaleData,
                },
                {
                    type: 'spline',
                    name: 'LTM Hiring Female',
                    data: ltmHiringPctFemaleData,
                    color: lineColors['Hiring'],
                },
                {
                    type: 'spline',
                    name: 'LTM Attrition Female',
                    data: ltmAttritionPctFemaleData,
                    color: lineColors['Attrition'],
                },
			],

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};
    };

    function getOptionsAdditionsAttritions() {
        const filteredData = data.filter((item) => {
            return item.geo1 === selectedGeo1;
        });
        const additionData = filteredData.map((item) => {
            let growthPct = item.new_headcount/item.total_headcount;
            return [item.the_date, parseFloat((growthPct * 100).toFixed(2))];
        });
        const attritionData = filteredData.map((item) => {
            let attritionPct = item.lost_headcount/item.total_headcount;
            return [item.the_date, parseFloat((-attritionPct * 100).toFixed(2))];
        });

        return {
			chart: {
				zoomType: 'x',
                type: 'column',
                height: '33.3%',
			},
			title: {
				text: 'Headcount Hiring and Attritions',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime'
			},
			yAxis: {
				title: {
					text: 'Addition vs Attrition'
				},
                labels: {
					format: '{value}%'
				}
			},
			legend: {
				enabled: true,
			},
			
			series: [
				{
					type: 'column',
					name: 'Hiring',
					data: additionData,
                    color: lineColors['Hiring'],
				},
                {
                    type: 'column',
                    name: 'Attrition',
                    data: attritionData,
                    color: lineColors['Attrition'],
                }
			],

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    pointWidth: 10,
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};
    };

    useEffect(() => {
        try {
            setIsMetaDataLoading(true);
            const getCompanyMetaData = async() => {
                let apiUrl = getApiServrUrl();
                apiUrl += process.env.REACT_APP_API_METADATA_ENDPOINT;
                console.log('CompanyGrowth: Fetching company metadata from ' + apiUrl + '...');
                let data = {
                    "company_ids": [parseInt(companyId)],
                };
        
                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    },
                };
        
                try {
                    const response = await axios.post(apiUrl, data, config);
                    console.log(response);
                    if(response.status === 200 && response.data.data.metadata.length !== 0) {
                        const metaData = JSON.parse(response.data.data.metadata);
                        setCompanyName(metaData[0].name);
                        if (metaData[0].num_valid_public_profiles) {
                            setPublicProfileCount(metaData[0].num_valid_public_profiles);
                        }
                    }
                }
                catch(err) {
                    if (err.response && err.response.status == 401 || err.response.status === 403) {
                        setIsUnAuthorized(true);
                    }
                    console.log(err);
                }
                
                setIsMetaDataLoading(false);
            };
            getCompanyMetaData();
        }
        catch(err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        try {
            if (publicProfileCount !== 0) {
                setIsPageLoading(true);
                getGrowthData();
                getUniverseGrowthAvgData();
                getAdditionsData();
                getAttritionsData();
            }
        }
        catch(err) {
            console.log(err);
        }
    }, [publicProfileCount]);

    useEffect(() => {
        try {
            if (publicProfileCount !== 0) {
                getAdditionsData();
                getAttritionsData();
            }
        }
        catch(err) {
            console.log(err);
        }
    }, [additionAttritionDate]);

    useEffect(() => {
        try {
            if (data !== null && universeAvgData !== null) {
                let growthChartOptions = getOptionsGrowth();
                Highcharts.chart('growth-chart-container', growthChartOptions);

                let totalHeadcountOptions = getOptionsTotalHeadcount();
                Highcharts.chart('total-headcount-chart-container', totalHeadcountOptions);

                let companyGenderOptions = getOptionsGenderHeadcount();
                Highcharts.chart('company-gender-chart-container', companyGenderOptions);

                let additionAttritonOptions = getOptionsAdditionsAttritions();
                Highcharts.chart('addition-attrition-chart-container', additionAttritonOptions);         
            }
        }
        catch(err) {
            console.log(err);
        }
    }, [data, universeAvgData, isUnAuthorized, companyName, publicProfileCount, selectedGeo1]);

    const handleOption1Change = (event) => {
        setSelectedGeo1(event.target.value);
    };

    if (isUnAuthorized) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. Please contact your admin for more details.</p>
            </div>
        );
    }
    else if ( publicProfileCount === 0) {
        if (isMetaDataLoading) {
            return (
                <div style={{ textAlign: "center" }} >
                    <ClipLoader />
                </div>
            );
        }
        else {
            return (
                <div style={{ textAlign: "center" }} >
                    <h1>No data</h1>
                    <p>Sorry, but the asset data you're requesting is currently unavailable.</p>
                </div>
            );
        }
    }
    else {
        return (
            <div>
                <div style={{ display: 'flex'}}>
                    <div style={{display: 'flex', justifyContent: 'flex-start', padding: '10px'}}>
                        <NavLink
                            to={universeSummaryPath}
                            state={{universeId: universeId, accessToken: accessToken}}
                        > Universe Summary
                        </NavLink>
                    </div>
                    <div style={{display: 'flex', flex: '1', justifyContent: 'flex-end', padding: '10px'}}>
                        <NavLink 
                            to={teamGrowthPath}
                            state={{universeId: universeId, companyId: companyId, accessToken: accessToken, companyName: companyName}}
                        > Team Growth 
                        </NavLink>
                        <div style={{ padding: '10px'}}></div>
                        <NavLink 
                            to={talentFlowPath}
                            state={{universeId: universeId, companyId: companyId, accessToken: accessToken, companyName: companyName}}
                        > Talent Flow
                        </NavLink>
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <h1 style={{marginTop: '0px', marginBottom: '10px'}}>
                        Company Growth: {companyName}
                    </h1>
                </div>
                { isPageLoading ? (
                    <div style={{ textAlign: "center" }} ><ClipLoader/> </div>
                ) : (
                <div>
                <div style={{textAlign: 'center'}}>
                    <label>
                        Region:&nbsp;
                        <select value={selectedGeo1} onChange={handleOption1Change}>
                            {
                                geo1Values.map((value) => (
                                        <option key={value} value={value}>
                                        {value}
                                        </option>
                                    )
                                )
                            }
                        </select>
                    </label>
                </div>
                <br/>
                <div className='chart-container'>
                    <div className="chart-container-left">
                        <div className="chart-container-left-child" id="growth-chart-container"></div>
                        <div className='chart-container-left-child' id="addition-attrition-chart-container"></div>
                        <div className='chart-container-left-child' id="company-gender-chart-container"></div>
                        <div className="chart-container-left-child" id="total-headcount-chart-container"></div>
                    </div>
                    <div className='chart-container-right'>
                        <div className = 'chart-container-right-child' id="addition-container">
                            <p> Click on the Headcount Growth chart to list additions/attritions for a particular time</p>
                            <h3>Employee Addition ({additionAttritionDate.toLocaleString('default',  {month: 'long', year: 'numeric'})})</h3>
                            { isAdditionAttritionLoading ? (
                                <ClipLoader/>
                            ) : additionData ? (
                                
                                <ul>
                                    { 
                                        additionData.map((item, index) => (
                                            <li key={index}>
                                                <a href= {item.linkedin_bug} rel="noreferrer" target="_blank">{item.title}</a>
                                            </li>
                                    ))
                                    }
                                </ul>
                            ) : (
                                <p> No data </p>
                            )}
                        </div>
                        <div className = 'chart-container-right-child' id="attrition-container">
                            <h3>Employee Attrition ({additionAttritionDate.toLocaleString('default',  {month: 'long', year: 'numeric'})})</h3>
                            { isAdditionAttritionLoading ? (
                                <ClipLoader/>
                            ) : attritionData ? (
                                <ul>
                                    { 
                                        attritionData.map((item, index) => (
                                            <li key={index}>
                                                <a href= {item.linkedin_bug} rel="noreferrer" target="_blank">{item.title}</a>
                                            </li>
                                        ))
                                        
                                    }
                                </ul>
                            ) : (
                                <p> No data </p>
                            )}
                        </div>
                    </div>
                </div>
                <div style={{ margin:'0 auto', textAlign: 'center'}}>
                    <DownloadButton 
                        data={data} 
                        companyName={companyName} 
                        buttonText={ 'Download Growth Data' }
                        fileName={ `${companyName}_growth_data.csv` }
                    />
                </div>
                <div style={{ margin: '10px'}}>
                    Notes:
                    <ul>
                        <li>LTM = Last Twelve Months</li>
                        <li>Public Profiles = Public profiles on LinkedIn</li>
                    </ul>
                </div>
                </div> )}
            </div>
        );
    }   
};

export default UniverseCompanyGrowthChartComponent;
