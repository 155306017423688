import { useEffect, useState } from "react";
import Highcharts from 'highcharts';
import HighChartsSankey from 'highcharts/modules/sankey';
import axios from "axios";
import { ClipLoader } from 'react-spinners';
import { useLocation, useSearchParams, NavLink } from "react-router-dom";
import { customSort, getApiServrUrl, getLastDateOfMonth, getGoogleAnalyticsId } from "../utils/utils";
import { DownloadButton } from "./download_button";
import Select from 'react-select';


HighChartsSankey(Highcharts);

const CompanyTalentComponent = () => {
    const [talentFlowData, setTalentFlowData] = useState({});
    const [selectedCheckbox, setSelectedCheckbox] = useState({ id: 5, value: getPastDate('3 years'), label: '3 years' });
    const [totalInflow, setTotalInflow] = useState(0);
    const [totalOutflow, setTotalOutflow] = useState(0);
    const [privateInflow, setPrivateInflow] = useState(0);
    const [pendingOutflow, setPendingOutflow] = useState(0);

    const [hiringDensityData, setHiringDensityData] = useState([]);
    const [leavingDensityData, setLeavingDensityData] = useState([]);
    const [companyName, setCompanyName] = useState('');
    
    const [geo1Values, setGeo1Values] = useState([]);
    const [deptValues, setDeptValues] = useState([]);
    const [seniorityValues, setSeniorityValues] = useState([]);

    const [selectedRegion, setSelectedRegion] = useState({value: 'All', label: 'All'});
    const [selectedDepartment, setSelectedDepartment] = useState({value: 'All', label: 'All'});
    const [selectedSeniority, setSelectedSeniority] = useState({value: 'All', label: 'All'});

    const [isPageLoading, setIsPageLoading] = useState(false);

    const [searchParams] = useSearchParams();
    const location = useLocation();

    const companyId = searchParams && searchParams.get('company_id') || location.state && location.state.companyId;
    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.accessToken;
    
    const companyGrowthPath = `/company/growth?company_id=${companyId}&access_token=${accessToken}`;
    const teamGrowthPath = `/company/team/growth?company_id=${companyId}&access_token=${accessToken}`;
    const talentFlowPath = `/company/talent?company_id=${companyId}&access_token=${accessToken}`;
    const tenurePath = `/company/tenure?company_id=${companyId}&access_token=${accessToken}`;
    
    const timeOptions = [
        { id: 1, value: getPastDate('3 months'), label: '3 months' },
        { id: 2, value: getPastDate('6 months'), label: '6 months' },
        { id: 3, value: getPastDate('1 year'), label: '1 year' },
        { id: 4, value: getPastDate('2 years'), label: '2 years' },
        { id: 5, value: getPastDate('3 years'), label: '3 years' },
        { id: 6, value: getPastDate('5 years'), label: '5 years' },
        { id: 7, value: getPastDate('7 years'), label: '7 years' },
        { id: 8, value: getPastDate('10 years'), label: '10 years' },
    ];

    function getPastDate(duration) {
        const dataVersionDate = new Date(process.env.REACT_APP_DATA_VERSION_DATE);
        switch (duration) {
            case '3 months':
                return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 3);

            case '6 months':
                return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 6);
            case '1 year':
                return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 12);
            case '2 years':
                return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 24);
            case '3 years':
                return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 36);
            case '5 years':
                return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 60);
            case '7 years':
                return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 84);
            case '10 years':
                return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 120);
            default:
                return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 3);
        };
    };

    const handleCheckboxChange = (option) => {
        setSelectedCheckbox(option);
    };
    
    const handleDeptChangeWithSelect = (selectedOption) => {
        if (selectedOption === null || selectedOption.length === 0) {
            console.warn('Atleast one department must be selected');
            return;
        }
        console.log('selectedDepartments: ', selectedOption);
        setSelectedDepartment(selectedOption);
    };

    const handleSeniorityChangeWithSelect = (selectedOption) => {
        if (selectedOption === null || selectedOption.length === 0) {
            console.warn('Atleast one seniority must be selected');
            return;
        }
        console.log('selectedSeniorities: ', selectedOption);
        setSelectedSeniority(selectedOption);
    };

    const handleGeo1ChangeWithSelect = (selectedOption) => {
        if (selectedOption === null || selectedOption.length === 0) {
            console.warn('Atleast one region must be selected');
            return;
        }
        console.log('selectedRegions: ', selectedOption);
        setSelectedRegion(selectedOption);
    };

    const getTalentFlowData = async() => {
        let apiUrl = getApiServrUrl();
        let talentInUrl = apiUrl + process.env.REACT_APP_API_TALENT_INFLOW_ENDPOINT;
        let talentOutUrl = apiUrl + process.env.REACT_APP_API_TALENT_OUTFLOW_ENDPOINT;

        let data = {
            // "company_ids": [919192, , 926354, 950648, 907611, ],
            "company_ids": [parseInt(companyId)],
        };
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
        };

        try {
            // talent in
            console.log(`CompanyTalent: Fetching talent in data from ${talentInUrl}...`);
            const response = await axios.post(talentInUrl, data, config);
            const jsonData = JSON.parse(response.data.data.sankey_in_history);

            let geo1Values = [... new Set(jsonData.map((item) => item.geo1))];
            geo1Values.push('All');
            let sortedGeo1Values = customSort(geo1Values, ['All', 'North America', 'Europe, Middle East & Africa', 'Latin America', 'Asia/Pacific']);
            setGeo1Values(sortedGeo1Values);

            let deptValues = [... new Set(jsonData.map((item) => item.department_group))];
            deptValues.push('All');
            const sortedDeptValues = customSort(deptValues, ['All', 'Sales', 'Product']);
            setDeptValues(sortedDeptValues);

            let seniorityValues = [... new Set(jsonData.map((item) => item.seniority_group))];
            seniorityValues.push('All');
            const sortedSeniorityOrder = [
                "All",
                "Individual Contributor",
                "Advisor / Board",
                "Executive",
                "Leadership",
                "Management",
                "Student / Intern",
                "Unsure"
            ];
            const sortedSeniorityValues = customSort(seniorityValues, sortedSeniorityOrder);
            setSeniorityValues(sortedSeniorityValues);

            setCompanyName(jsonData[0]['company_name']);

            const hiringDensityDataJson = JSON.parse(response.data.data.sankey_in_density);
            setHiringDensityData(hiringDensityDataJson); 

            // talent out
            console.log(`CompanyTalent: Fetching talent out data from ${talentOutUrl}...`);
            const response2 = await axios.post(talentOutUrl, data, config);
            const jsonData2 = JSON.parse(response2.data.data.sankey_out_history);

            const leavingDensityDataJson = JSON.parse(response2.data.data.sankey_out_density);
            setLeavingDensityData(leavingDensityDataJson);

            const talentFlowData = {
                "talentIn": jsonData,
                "talentOut": jsonData2,
            };
            setTalentFlowData(talentFlowData);

            setIsPageLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    function getSankeyChartOptions () {
        if (talentFlowData === null || talentFlowData === undefined) {
            return {};
        }
        else if (talentFlowData['talentIn'] === null || talentFlowData['talentIn'] === undefined) {
            return {};
        }
        else if (talentFlowData['talentOut'] === null || talentFlowData['talentOut'] === undefined) {
            return {};
        }

        let sankeyInData = talentFlowData['talentIn'];
        let sankeyOutData = talentFlowData['talentOut'];
        let filteredSankeyInData = [];
        let filteredSankeyOutData = [];
        if (selectedRegion.value !== "All" || selectedDepartment.value !== "All" || selectedSeniority.value !== "All") {
            let isGeo1Selected = selectedRegion.value !== "All";
            let isDeptSelected = selectedDepartment.value !== "All";
            let isSenioritySelected = selectedSeniority.value !== "All";

            if (isGeo1Selected) {
                filteredSankeyInData = sankeyInData.filter((item) => item.geo1 === selectedRegion.value);
                filteredSankeyOutData = sankeyOutData.filter((item) => item.geo1 === selectedRegion.value);
                if (isDeptSelected) {
                    filteredSankeyInData = filteredSankeyInData.filter((item) => item.department_group === selectedDepartment.value);
                    filteredSankeyOutData = filteredSankeyOutData.filter((item) => item.department_group === selectedDepartment.value);
                }
                if (isSenioritySelected) {
                    filteredSankeyInData = filteredSankeyInData.filter((item) => item.seniority_group === selectedSeniority.value);
                    filteredSankeyOutData = filteredSankeyOutData.filter((item) => item.seniority_group === selectedSeniority.value);
                }
            }
            if (isDeptSelected) {
                filteredSankeyInData = sankeyInData.filter((item) => item.department_group === selectedDepartment.value);
                filteredSankeyOutData = sankeyOutData.filter((item) => item.department_group === selectedDepartment.value);
                if (isGeo1Selected) {
                    filteredSankeyInData = filteredSankeyInData.filter((item) => item.geo1 === selectedRegion.value);
                    filteredSankeyOutData = filteredSankeyOutData.filter((item) => item.geo1 === selectedRegion.value);
                }
                if (isSenioritySelected) {
                    filteredSankeyInData = filteredSankeyInData.filter((item) => item.seniority_group === selectedSeniority.value);
                    filteredSankeyOutData = filteredSankeyOutData.filter((item) => item.seniority_group === selectedSeniority.value);
                }
            }
            if (isSenioritySelected) {
                filteredSankeyInData = sankeyInData.filter((item) => item.seniority_group === selectedSeniority.value);
                filteredSankeyOutData = sankeyOutData.filter((item) => item.seniority_group === selectedSeniority.value);
                if (isGeo1Selected) {
                    filteredSankeyInData = filteredSankeyInData.filter((item) => item.geo1 === selectedRegion.value);
                    filteredSankeyOutData = filteredSankeyOutData.filter((item) => item.geo1 === selectedRegion.value);
                }
                if (isDeptSelected) {
                    filteredSankeyInData = filteredSankeyInData.filter((item) => item.department_group === selectedDepartment.value);
                    filteredSankeyOutData = filteredSankeyOutData.filter((item) => item.department_group === selectedDepartment.value);
                }
            }
        }
        else {
            filteredSankeyInData = sankeyInData;
            filteredSankeyOutData = sankeyOutData;
        }

        if (selectedCheckbox.label !== '3 years') {
            const selectedDate = new Date(selectedCheckbox.value);
            let timeDurationMonth = selectedDate.getUTCMonth() + 1;
            let timeDurationYear = selectedDate.getUTCFullYear();
            let startTime = new Date(timeDurationYear, timeDurationMonth, 1).getTime();
            filteredSankeyInData = filteredSankeyInData.filter((item) => {
                let itemDate = new Date(item['date_from']);
                let dateStr = itemDate.getUTCFullYear() + '/' + (itemDate.getUTCMonth() + 1) + '/' + itemDate.getUTCDate();
                let date = new Date(dateStr);
                return date.getTime() >= startTime
            });
            filteredSankeyOutData = filteredSankeyOutData.filter((item) => {
                let itemDate = new Date(item['date_to']);
                let dateStr = itemDate.getUTCFullYear() + '/' + (itemDate.getUTCMonth() + 1) + '/' + itemDate.getUTCDate();
                let date = new Date(dateStr);
                return date.getTime() >= startTime
            });

        }


        let companyEmpInCountMap = {};
        let totalEmpInCount = 0;

        for (let i = 0; i < filteredSankeyInData.length; i++) {
            totalEmpInCount += filteredSankeyInData[i]['employee_count'];
            
            if (filteredSankeyInData[i]['past_company_name'] in companyEmpInCountMap) {
                companyEmpInCountMap[filteredSankeyInData[i]['past_company_name']] += filteredSankeyInData[i]['employee_count'];
            } else {
                companyEmpInCountMap[filteredSankeyInData[i]['past_company_name']] = filteredSankeyInData[i]['employee_count'];
            };
        };
        
        const companyEmpInCountMapSortedByCount = Object.entries(companyEmpInCountMap).sort((a, b) => b[1] - a[1]);

        let totalEmpOutCount = 0;
        let companyEmpOutCountMap = {};
        for (let i = 0; i < filteredSankeyOutData.length; i++) {
            totalEmpOutCount += filteredSankeyOutData[i]['employee_count'];
            
            if (filteredSankeyOutData[i]['current_company_name'] in companyEmpOutCountMap) {
                companyEmpOutCountMap[filteredSankeyOutData[i]['current_company_name']] += filteredSankeyOutData[i]['employee_count'];
            } else {
                companyEmpOutCountMap[filteredSankeyOutData[i]['current_company_name']] = filteredSankeyOutData[i]['employee_count'];
            };
        };

        const companyEmpOutCountMapSortedByCount = Object.entries(companyEmpOutCountMap).sort((a, b) => b[1] - a[1]);

        console.log(companyEmpInCountMapSortedByCount);
        setTotalInflow(totalEmpInCount);
        setTotalOutflow(totalEmpOutCount);
        setPrivateInflow(companyEmpInCountMap['Private'] || 0);
        setPendingOutflow(companyEmpOutCountMap['Pending'] || 0);
       
        const sankeyInSeriesData = [];
        const sankeyOutSeriesData = [];

        let count = 0;
        for (let i = 0; i < companyEmpInCountMapSortedByCount.length; i++) {
            if (companyEmpInCountMapSortedByCount[i][0] !== 'Private') {
                sankeyInSeriesData.push({
                    id: count,
                    from: companyEmpInCountMapSortedByCount[i][1] + ':' + companyEmpInCountMapSortedByCount[i][0],
                    to: companyName,
                    weight: companyEmpInCountMapSortedByCount[i][1],
                    color: '#999999',
                });
            };
            if (count === 25){
                break;
            }
            count += 1;
        };
        
        for (let i = 0; i < companyEmpOutCountMapSortedByCount.length; i++) {
            if (companyEmpOutCountMapSortedByCount[i][0] !== 'Pending') {
                sankeyOutSeriesData.push({
                    id: count,
                    from: companyName,
                    to: companyEmpOutCountMapSortedByCount[i][0] + ':' + companyEmpOutCountMapSortedByCount[i][1],
                    weight: companyEmpOutCountMapSortedByCount[i][1],
                    color: '#999999',
                });
            };
            if (count === 50) {
                break;
            }
            count += 1;
        };

        const sankeyChartSeriesData = sankeyInSeriesData.concat(sankeyOutSeriesData);
        const startDateLabel = new Date(selectedCheckbox.value).toLocaleString('default',  {month: 'long', year: 'numeric'});
        const endDateLabel = new Date(process.env.REACT_APP_DATA_VERSION_DATE).toLocaleString('default',  {month: 'long', year: 'numeric'});

        const dateLabel  = startDateLabel + ' - ' + endDateLabel;
        return {
            chart: {
                height: '45%',
                renderTo: 'talent-flow-chart-container',
            },

			title: {
				text: `Talent Inflow/Outflow (Public Profiles) ${dateLabel}`,
				align: 'left'
			},
			
			legend: {
				enabled: true,
			},
			
			series: [{
				keys: ['from', 'to', 'weight'],
                data: sankeyChartSeriesData,
                type: 'sankey',
                name: 'Talent Flow',
                // links: links,
                linkOpacity: 0.5,
                // nodes: nodes,
                tooltip: {
                    nodeFormatter: function () {
                        return this.name.split(':')[1];
                    },
                    pointFormatter: function () {
                        if(this.toNode.name === companyName) {
                            // inflow hover
                            return this.fromNode.name.split(':')[1] + ' -> ' + this.toNode.name + ': ' + this.weight;
                        } else {
                            // outflow hover
                            return this.fromNode.name + ' -> ' + this.toNode.name.split(':')[0] + ': ' + this.weight;
                        }
                        //return this.fromNode.name + ' -> ' + this.toNode.name + ': ' + this.weight;
                    }
                },
            }],

            plotOptions: {
                sankey: {
                    nodeWidth: 10,
                },
            },

			credits: {
				enabled: false
			},

            xAxis: {
                type: 'datetime'
              },
              yAxis: {
                title: {
                  text: 'Value'
                }
              },
	
		};
    };

    function getHiringDensityChartOptions() {
        const categories = [];
        const hiringDensityDataSeries = hiringDensityData.map((item) => {
            categories.push(item.bins);
            return [item.bins, parseFloat((item.employee_percentage * 100).toFixed(1))];
        });

        return {
			chart: {
				zoomType: 'xy',
                type: 'column',
                height: '75%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Hiring Density - Inflow',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
            xAxis: {
                categories: categories,
                title: {
                    text: 'Number of employees hired from a single org'
                },
            },
			yAxis: {
				title: {
                    text: 'Pct of Employees'
                },
                labels: {
					format: '{value}%'
				}
			},
			legend: {
				enabled: true,
			},
            tooltip: {
                formatter: function() {
                    return `${this.series.name}: ${this.y}%`;
                }
            },
			
			series: [
				{
                    name: 'Hiring Density',
					type: 'column',
					data: hiringDensityDataSeries,
                    color: 'green',
				},
			],

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    pointWidth: 20,
                },
            },
	
		};

    };

    function getLeavingDensityChartOptions() {
        const categories = [];
        const leavingDensityDataSeries = leavingDensityData.map((item) => {
            categories.push(item.bins);
            return [item.bins, parseFloat((item.employee_percentage * 100).toFixed(1))];
        });

        return {
			chart: {
				zoomType: 'xy',
                type: 'column',
                height: '75%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Leaving Density - Outflow',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
            xAxis: {
                categories: categories,
                title: {
                    text: 'Number of employees departed to a single org'
                },
                
            },
			yAxis: {
				title: {
                    text: 'Pct of Employees'
                },
                labels: {
					format: '{value}%'
				}
			},
			legend: {
				enabled: true,
			},
			tooltip: {
                formatter: function() {
                    return `${this.series.name}: ${this.y}%`;
                }
            },
			series: [
				{   
                    name: 'Leaving Density',
					type: 'column',
					data: leavingDensityDataSeries,
                    color: 'red',
				},
			],

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    pointWidth: 20,
                },
            },
	
		};

    };

    useEffect(() => {
        setIsPageLoading(true);
        getTalentFlowData();
    }, []);

    useEffect(() => {
        if (isPageLoading === false) {
            const sankeyChartOptions = getSankeyChartOptions();
            Highcharts.chart('talent-flow-chart-container', sankeyChartOptions);

            const hiringDensityChartOptions = getHiringDensityChartOptions();
            Highcharts.chart('hiring-density-chart-container', hiringDensityChartOptions);

            const leavingDensityChartOptions = getLeavingDensityChartOptions();
            Highcharts.chart('leaving-density-chart-container', leavingDensityChartOptions);
        };

    }, [talentFlowData, selectedRegion.value, selectedDepartment.value, selectedSeniority, selectedCheckbox]);

    const customStyles = {
        control: (provided) => ({
        ...provided,
        width: 200,
        }),
        menu: (provided) => ({
        ...provided,
        width: 200,
        }),
    };

    const trackNavLinkClick = (label) => {
        // Check if gtag function is available
        if (typeof window.gtag === 'function') {
            console.log('TalentFlowPage: Tracking navlink click event with label: ' + label);
            const gAnalyticsId = getGoogleAnalyticsId();
            window.gtag('config', gAnalyticsId, {
                'user_id': accessToken
            });
            window.gtag('set', 'user_properties', {
                'token': accessToken
            });
            window.gtag('event', 'navlink_click', {
                'event_category': 'navigation',
                'event_label': label
            });
        }
    };

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'flex-start', padding: '10px'}}>
                <NavLink 
                    style={{fontWeight: 'bold', color: 'blue', padding: '10px', backgroundColor: 'lightgray', borderRadius: '20px'}}
                    to={companyGrowthPath}
                    state={{companyId: companyId, companyName: companyName, accessToken: accessToken}}
                    onClick={() => trackNavLinkClick(`Company Growth - ${companyName} clicked`)}
                > Company
                </NavLink>
                <div style={{ padding: '10px'}}></div>
                <NavLink 
                    style={{fontWeight: 'bold', color: 'blue', padding: '10px', backgroundColor: 'lightgray', borderRadius: '20px'}}
                    to={teamGrowthPath}
                    state={{companyId: companyId, companyName: companyName, accessToken: accessToken}}
                    onClick={() => trackNavLinkClick(`Teams- ${companyName} clicked`)}
                > Teams
                </NavLink>
                <div style={{ padding: '10px'}}></div>
                <NavLink 
                    style={{fontWeight: 'bold', color: 'blue', padding: '10px', backgroundColor: 'lightgray', borderRadius: '20px'}}
                    to={talentFlowPath}
                    state={{companyId: companyId, companyName: companyName, accessToken: accessToken}}
                    onClick={() => trackNavLinkClick(`Talent Flow - ${companyName} clicked`)}
                > Talent Flow
                </NavLink>
                <div style={{ padding: '10px'}}></div>
                <NavLink 
                    style={{fontWeight: 'bold', color: 'blue', padding: '10px', backgroundColor: 'lightgray', borderRadius: '20px'}}
                    to={tenurePath}
                    state={{companyId: companyId, companyName: companyName, accessToken: accessToken}}
                    onClick={() => trackNavLinkClick(`Tenure - ${companyName} clicked`)}
                > Tenure
                </NavLink>
            </div>
            <div style={{textAlign: 'center'}}>
                <h1 style={{marginTop: '0px', marginBottom: '10px'}}>
                    Talent Flow: {companyName}
                </h1>
            </div>
            { isPageLoading ? (
                <div style={{ textAlign: "center" }} ><ClipLoader/> </div>
            ) : (
            <div>
            <div className='checkbox-group-container'> 
                <div className='checkbox-container'>
                    <label htmlFor='regions'>Region:</label>
                    &nbsp;&nbsp;
                    <Select
                        name="regions"
                        options={geo1Values.map((value) => ({value: value, label: value}))}
                        value={selectedRegion}
                        onChange={handleGeo1ChangeWithSelect}
                        styles={customStyles}
                    />
                </div>
                <div className='checkbox-container'>
                    <label htmlFor='departments'>Department:</label>
                    &nbsp;&nbsp;
                    <Select
                        name="departments"
                        options={deptValues.map((value) => ({value: value, label: value}))}
                        value={selectedDepartment}
                        onChange={handleDeptChangeWithSelect}
                        styles={customStyles}
                    />
                </div>
                <div className='checkbox-container'>
                    <label htmlFor='seniorities'>Seniority:</label>
                    &nbsp;&nbsp;
                    <Select
                        name="seniorities"
                        options={seniorityValues.map((value) => ({value: value, label: value}))}
                        value={selectedSeniority}
                        onChange={handleSeniorityChangeWithSelect}
                        styles={customStyles}
                    />
                </div>
            </div>
            <br/>
            <div className="checkbox-group-container">
                { timeOptions.map((timeOption) => (
                    <div key={timeOption.id} className="checkbox-container" >
                        <input
                            type="radio"
                            id={timeOption.id}
                            name="timeOption"
                            defaultChecked={timeOption.label === "3 years"}
                            onChange={() => handleCheckboxChange(timeOption)}
                        />
                        <label htmlFor={timeOption.id}>{timeOption.label}</label>
                    </div>
                ))}
            </div>
            <div className='chart-container'>
                <div className='chart-container-left-talent' >
                    <div id="talent-flow-chart-container"></div>
                    <div>
                        {/* <div style={{width: '100%', padding: '10px'}}> */}
                        <div style={{display: 'flex'}}>

                            <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
                                <ul style={{ listStyleType: 'none', textAlign: 'left'}}>
                                    <li>Total Inflow: {totalInflow}</li>
                                    <li>Private: {privateInflow}</li>
                                </ul>
                            </div>
                            <div style={{display: 'flex', flex: '1', alignItems: 'stretch', flexDirection: 'column'}}>
                                <ul style={{ listStyleType: 'none', textAlign: 'right', marginRight: '30px'}}>
                                    <li>Total Outflow: {totalOutflow}</li>
                                    <li>Pending: {pendingOutflow}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='chart-container-right'>
                    <div className = 'chart-container-right-child-sankey' id="hiring-density-chart-container">
                        <h3>Hiring Density</h3>
                        
                    </div>
                    <div className = 'chart-container-right-child-sankey' id="leaving-density-chart-container">
                        <h3>Leaving Density</h3>
                        
                    </div>
                </div>
            </div>
            <div style={{ margin:'0 auto', textAlign: 'center'}}>
                <DownloadButton 
                    data = {talentFlowData} 
                    companyName={companyName} 
                    buttonText = {'Download Talent Flow Data'}
                    fileName = { `${companyName}_talent_flow_data.xlsx` }
                    talentFlow = {true}
                />
            </div>
            <div style={{ margin: '10px'}}>
                Notes:
                <ul>
                    <li>Data as of {getLastDateOfMonth(process.env.REACT_APP_DATA_VERSION_DATE).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</li>
                    <li>All data and analysis is based on publicly available profiles</li>
                    <li>Top 25 companies listed on inflow and outflow</li>
                    <li>'Private' inflow means that the employee started at the target company, but the employee's previous experience is not known</li>
                    <li>'Pending' outflow means that the employee has ended his/her experience at the target company, but has not yet specified a subsequent experience</li>
                    <li>Hiring Density and Leaving Density calculations do not include Private and Pending inflow/outflows</li>
                </ul>
            </div>
            </div> )}
        </div>
    );
};

export default CompanyTalentComponent;
