import React from "react";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import moment from "moment";
import * as XLSX from 'xlsx';

export const DownloadButton = (props) => {
    const buttonText = props.buttonText;
    const formatData = (propsObj) => {
        let data = propsObj.data;
        if (propsObj.companyGrowth) {
            return data.map(item => ({
                'Date': moment.utc(item.the_date).format('YYYY-MM-DD'),  // format date field
                'Company Name': propsObj.companyName,
                'Region': item.geo1,
                'Total Headcount (Public Profiles)': item.total_headcount,
                'Retained Headcount (Public Profiles)': item.retained_headcount,
                'Lost Headcount (Public Profiles)': item.lost_headcount,
                'New Headcount (Public Profiles)': item.new_headcount,
                'Net Headcount Growth (Last 12 Mo)': (item.ltm_net_hc_growth * 100).toFixed(1) + "%",
                'Attrition Rate (Last 12 Mo)': (item.ltm_attrition_rate * 100).toFixed(1) + "%",
                'Hiring Rate (Last 12 Mo)': (item.ltm_addition_rate * 100).toFixed(1) + "%",
                'Growth Productivity (Last 12 Mo)': (item.ltm_growth_productivity * 100).toFixed(1) + "%",
                'Pct Female, Company Avg': (item.company_hc_total_avg_f_prob * 100).toFixed(1) + "%",
                'Pct Female, New Hires (Last 12 Mo)': (item.company_hc_new_avg_f_prob_prev_12_mo * 100).toFixed(1) + "%",
                'Pct Female, Attrits (Last 12 Mo)' : (item.company_hc_lost_avg_f_prob_prev_12_mo * 100).toFixed(1) + "%",
                // add more formatting here if needed
            }));
        }
        if (propsObj.teamsGrowth) {
            return data.map(item => ({
                'Date': moment.utc(item.the_date).format('YYYY-MM-DD'),  // format date field
                'Company Name': propsObj.companyName,
                'Department': item.department,
                'Seniority': item.seniority,
                'Region': item.geo1,
                'Total Headcount (Public Profiles)': item.total_headcount,
                'Retained Headcount (Public Profiles)': item.retained_headcount,
                'Lost Headcount (Public Profiles)': item.lost_headcount,
                'New Headcount (Public Profiles)': item.new_headcount,
                'Net Headcount Growth (Last 12 Mo)': (item.ltm_net_hc_growth * 100).toFixed(1) + "%",
                'Attrition Rate (Last 12 Mo)': (item.ltm_attrition_rate * 100).toFixed(1) + "%",
                'Hiring Rate (Last 12 Mo)': (item.ltm_addition_rate * 100).toFixed(1) + "%",
                'Growth Productivity (Last 12 Mo)': (item.ltm_growth_productivity * 100).toFixed(1) + "%",
                'Pct Female, Company Avg': (item.company_hc_total_avg_f_prob * 100).toFixed(1) + "%",
                'Pct Female, New Hires (Last 12 Mo)': (item.company_hc_new_avg_f_prob_prev_12_mo * 100).toFixed(1) + "%",
                'Pct Female, Attrits (Last 12 Mo)' : (item.company_hc_lost_avg_f_prob_prev_12_mo * 100).toFixed(1) + "%",
                // add more formatting here if needed
            }));
        }  
        if (propsObj.tenure) {
            return data.map(item => ({
                'Date': moment.utc(item.the_date).format('YYYY-MM-DD'),  // format date field
                'Company Name': propsObj.companyName,
                'Department': item.department,
                'Seniority': item.seniority,
                'Region': item.geo1,
                'Avg Career Tenure (Years)': item.avg_career_tenure_years !== null ? item.avg_career_tenure_years.toFixed(1) : 'N/A',
                'Avg Company Tenure (Years)': item.avg_company_tenure_years !== null ? item.avg_company_tenure_years.toFixed(1) : 'N/A',
                'Avg Career Tenure Female (Years)': item.avg_career_tenure_years_f !== null ? item.avg_career_tenure_years_f.toFixed(1) : 'N/A',
                'Avg Company Tenure Female (Years)': item.avg_company_tenure_years_f !== null ? item.avg_company_tenure_years_f.toFixed(1) : 'N/A',
                'Avg Career Tenure Male (Years)': item.avg_career_tenure_years_m !== null ? item.avg_career_tenure_years_m.toFixed(1) : 'N/A',
                'Avg Company Tenure Male (Years)': item.avg_company_tenure_years_m !== null ? item.avg_company_tenure_years_m.toFixed(1) : 'N/A',
                // add more formatting here if needed
            }));
        }
        
        // default return empty object
        return {};
    };

    const formatAndSaveTalentFlowData = (propsObj) => {
        let inflowData = propsObj.data['talentIn'];
        let outflowData = propsObj.data['talentOut'];

        const wb = XLSX.utils.book_new();

        // add inflow sheet
        const inflowFormattedData = inflowData.map(item => ({
            'Company Left': item.past_company_name,
            'Company Joined': item.company_name,
            'Month Joined': moment.utc(item.date_from).format('YYYY-MM-DD'),  // format date field
            'Region': item.geo1,
            'Number of People': item.employee_count,
        }));
        const ws = XLSX.utils.json_to_sheet(inflowFormattedData);
        XLSX.utils.book_append_sheet(wb, ws, 'Talent Inflow');

        // add outflow sheet
        const outflowFormattedData = outflowData.map(item => ({
            'Company Left': item.company_name,
            'Company Joined': item.current_company_name,
            'Month Left': moment.utc(item.date_to).format('YYYY-MM-DD'),  // format date field
            'Region': item.geo1,
            'Number of People': item.employee_count,
        }));
        const ws2 = XLSX.utils.json_to_sheet(outflowFormattedData);
        XLSX.utils.book_append_sheet(wb, ws2, 'Talent Outflow');

        console.log(wb);
        console.log(wb.Sheets);

        // Save as Excel file
        const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        saveAs(blob, propsObj.fileName);
    };

    const handleDownload = () => {
        if (props.talentFlow) {
            // save as excel file with two sheets
            formatAndSaveTalentFlowData(props);
        }
        else {
            // save as csv file
            const formattedData = formatData(props);
            const csv = Papa.unparse(formattedData);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const filename = props.fileName;
            saveAs(blob, filename);
        }
    };

    // Utility function to convert string to array buffer
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }

    return (
        <button 
            className='btn default' 
            onClick={ handleDownload }
        >
                {buttonText}
        </button>
    );
};
