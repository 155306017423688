import React from "react";
import { NavBarTab } from "./nav-bar-tab";

export const NavBarTabs = () => {
  return (
    <div className="nav-bar__tabs">
        <NavBarTab path="/company/growth" label="Company Growth" />
        <NavBarTab path="/company/team/growth" label="Team Growth" />
        <NavBarTab path="/company/talent" label="Talent Flow" />    
    </div>
  );
};
