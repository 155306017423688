import { useEffect, useState } from "react";
import { NavLink, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { getApiServrUrl, numberFormatter, numberToCurrencyUSDFormatter, toTitle } from "../utils/utils";
import axios from "axios";
import { universeDisplayNames, universeDisplayNamesForColumns } from "../utils/constants";
import Highcharts from 'highcharts';
import AnnotationsModule from 'highcharts/modules/annotations';
import { ClipLoader } from "react-spinners";
import { DownloadButton } from "./download_button";

AnnotationsModule(Highcharts);

const UniverseChartComponent = ()   => {
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [universeData, setUniverseData] = useState([]);
    const [seriesDataCompanyMapper, setSeriesDataCompanyMapper] = useState({});

    const [showAverage, setShowAverage] = useState(false);
    const [showMedian, setShowMedian] = useState(false);

    const [companyOptions, setCompanyOptions] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedPointIndex, setSelectedPointIndex] = useState(null);

    const [isUnAuthorized, setIsUnAuthorized] = useState(false);

    const [companySummaryData, setCompanySummaryData] = useState(null);
    const [companyInsightsUrl, setCompanyInsightsUrl] = useState('');
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalCompanies, setTotalCompanies] = useState(0);
    const [totalEmployees, setTotalEmployees] = useState(0);
    const [selectedChartView, setSelectedChartView] = useState(
        { 
            id: 1, 
            value: [
                universeDisplayNames['Employee Retention'],
                universeDisplayNames['Net Headcount Growth']
            ],
            label: 'Employee Retention vs Net Headcount Growth' 
        }
    );
    const [universeName, setUniverseName] = useState('');
    
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const universeId = searchParams && searchParams.get('universe_id') || location.state && location.state.universe_id;
    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.access_token;

    const chartViewOptions = [
        { 
            id: 1, 
            value: [
                universeDisplayNames['Employee Retention'],
                universeDisplayNames['Net Headcount Growth']
            ],
            label: 'Employee Retention vs Net Headcount Growth' 
        },
        { 
            id: 2, 
            value: [
                universeDisplayNames['Employee Retention'],
                universeDisplayNames['Employee Addition']
            ],
            label: 'Employee Retention vs Employee Addition' 
        },
        { 
            id: 3, 
            value: [
                universeDisplayNames['Employee Retention'],
                universeDisplayNames['Growth Productivity']
            ],
            label: 'Employee Retention vs Growth Productivity'
        },
        { 
            id: 4, 
            value: [
                universeDisplayNames['Employees'],
                universeDisplayNames['Net Headcount Growth']
            ],
            label: 'Employees vs Net Headcount Growth'
        },
    ];
    
    const getUniverseData = async () => {
        let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_UNIVERSE_SUMMARY_ENDPOINT;

        console.log(`UniverseSummary: Fetching universe summary data from ${apiUrl}...`);
        let data = {
            // "company_ids": [919192, , 926354, 950648, 907611, 4368043],
            //"universe_id": parseInt(universeId),
            "universe_id": `${universeId}`,
        }
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        };

        try {
            const response = await axios.post(apiUrl, data, config);
            if (response.data.data.universe_summary.length !== 0) {
                const jsonData = JSON.parse(response.data.data.universe_summary);
                setUniverseData(jsonData);
                setUniverseName(jsonData[0].universe_display_name);

                let rev = 0;
                let employees = 0;
                for (let i=0 ; i<jsonData.length ; i++) {
                    rev += jsonData[i].estimated_revenue;
                    employees += jsonData[i].company_employees_count_all;
                };
                setTotalRevenue(rev);
                setTotalEmployees(employees);
                setTotalCompanies(jsonData.length);
                let t = numberToCurrencyUSDFormatter(rev);
                let f = numberFormatter(employees);  
                console.log(t, f, jsonData.length);

                let companyOptions = ['All'];
                companyOptions.push(... new Set(jsonData.map((item) => item.company_name)));
                setCompanyOptions(companyOptions);
                setSelectedCompany('All');

                setIsPageLoading(false);

            }
        }
        catch (error) {
            if (error.response  && error.response.status === 401 || error.response === 403) {
                setIsUnAuthorized(true);
            } 
            console.log(error);
        }

    };
    const handleChartViewChange = (option) => {
        console.log(option);
        setSelectedChartView(option);
        setCompanySummaryData(null);
    };

    function getUniverseChartOptions() {
        const seriesData = [];
        let dataMapper = {};

        for (let i=0; i<universeData.length; i++) {
            let x = 0;
            let y = 0;

            if (selectedChartView.id === 1 || selectedChartView.id === 2 || selectedChartView.id === 3) {
                x = parseFloat((universeData[i][selectedChartView.value[0]] * 100).toFixed(2));  
                y = parseFloat((universeData[i][selectedChartView.value[1]] * 100).toFixed(2));
            }
            else {
                x = universeData[i][selectedChartView.value[0]]
                y = parseFloat((universeData[i][selectedChartView.value[1]] * 100).toFixed(2));
            }
            seriesData.push(
                [x, y]
            );

            let xy = x.toString() + ',' + y.toString();
            
            dataMapper[xy] = {
                index: i,
                companyName: universeData[i].company_name,
            };
        };
        setSeriesDataCompanyMapper(dataMapper);
        const annotationLables = seriesData.map((item) => {
            return {
                point: {
                    x: item[0],
                    y: item[1],
                    xAxis: 0,
                    yAxis: 0, 
                },
                text: dataMapper[item[0].toString() + ',' + item[1].toString()]['companyName'],

            };
        });

        let options = {
            annotations: [{
                labels: annotationLables,
                labelOptions: {
                    shape: 'connector',
                    align: 'right',
                    justify: false,
                    crop: true,
                    style: {
                        fontSize: '0.8em',
                    },
                    //allowOverlap: true,
                },
            }],
            chart: {
                type: 'scatter',
                height: '65%',
                zoomType: 'xy',
            },
            title: {
                text: universeName,
            },
            subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
            xAxis: {
                title: {
                    text: universeDisplayNamesForColumns[selectedChartView.value[0]],
                },
                labels: {
                    formatter: function() {
                        if (selectedChartView.id === 1 || selectedChartView.id === 2) {
                            return this.value + '%';
                        }
                        else {
                            return this.value;
                        }
                    }
				},
                gridLineWidth: 1,
            },
            yAxis: {
                title: {
                    text: universeDisplayNamesForColumns[selectedChartView.value[1]],
                },
                labels: {
					format: '{value}%'
				},
                gridLineWidth: 1,
            },
            series: [
                {
                    name: 'Universe Summary',
                    data: seriesData,
                    marker: {
                        symbol: 'circle',
                        states: {
                            select: {
                                fillColor: 'lightblue',
                                radius: 20,
                                lineWidth: 0,
                            },
                        },
                    },
                },
            ],
            tooltip: {
                formatter: function() {
                    let xLabel = universeDisplayNamesForColumns[selectedChartView.value[0]];
                    let yLabel = universeDisplayNamesForColumns[selectedChartView.value[1]];

                    let label = xLabel + ': ' + this.x + '<br/>' +
                                yLabel + ': ' + this.y;

                    return label;
                },               
            },
            plotOptions: {
                series: {
                    marker: {
                        radius: 8
                    },
                    point: {
                        events: {
                            click: function (event) {
                                let companyName = dataMapper[this.x + ',' + this.y]['companyName'];
                                setCompanySummary(companyName);
                            }
                        }
                    },
                },
            },
            credits: {
                enabled: false,
            }
        };

        if (showAverage) {
            const averages = calculateAverage(seriesData);
            console.log(averages);
            options.series.push(
                {
                    type: 'spline',
                    name: `Average ${universeDisplayNamesForColumns[selectedChartView.value[0]]}`,
                    data: [[averages[0], Math.min(...seriesData.map(d => d[1]))], [averages[0], Math.max(...seriesData.map(d => d[1]))]],
                    color: 'black',
                    marker: {
                        enabled: false,
                    },
                },
                {
                    type: 'spline',
                    name: `Average ${universeDisplayNamesForColumns[selectedChartView.value[1]]}`,
                    data: [[Math.min(...seriesData.map(d => d[0])), averages[1]], [Math.max(...seriesData.map(d => d[0])), averages[1]]],
                    color: 'black',
                    marker: {
                        enabled: false,
                    },
                }
            );

        };
        if (showMedian) {
            const medians = calculateMedian(seriesData);
            console.log(medians);
            options.series.push(
                {
                    type: 'spline',
                    name: `Median ${universeDisplayNamesForColumns[selectedChartView.value[0]]}`,
                    data: [[medians[0], Math.min(...seriesData.map(d => d[1]))], [medians[0], Math.max(...seriesData.map(d => d[1]))]],
                    color: 'green',
                    marker: {
                        enabled: false,
                    },
                },
                {
                    type: 'spline',
                    name: `Median ${universeDisplayNamesForColumns[selectedChartView.value[1]]}`,
                    data: [[Math.min(...seriesData.map(d => d[1])), medians[1]], [Math.max(...seriesData.map(d => d[0])), medians[1]]],
                    color: 'green',
                    marker: {
                        enabled: false,
                    },
                }
            );
        };

        return options;
    };

    function calculateAverage(seriesData) {
        let xSum = seriesData.reduce((sum, value) => sum + value[0], 0);
        const xAvg = xSum / seriesData.length;

        let ySum = seriesData.reduce((sum, value) => sum + value[1], 0);
        const yAvg = ySum / seriesData.length;

        return [xAvg, yAvg];
    };

    function calculateMedian(seriesData) {
        const xSorted = seriesData.map(item => item[0]).sort((a, b) => a - b);
        const mid = Math.floor(xSorted.length / 2);
        let xMedian = xSorted[mid];
        if (xSorted.length % 2 === 0) {
            xMedian = (xSorted[mid - 1] + xSorted[mid]) / 2;
        }
        
        const ySorted = seriesData.map(item => item[1]).sort((a, b) => a - b);
        const yMid = Math.floor(ySorted.length / 2);
        let yMedian = ySorted[yMid];
        if (ySorted.length % 2 === 0) {
            yMedian = (ySorted[yMid - 1] + ySorted[yMid]) / 2;
        }

        return [xMedian, yMedian];
    };

    function setCompanySummary(companyName) {
        let companyData = universeData.find(item => item.company_name === companyName);
        // add company_linkedin_url to companyData
        let companyLinkedInUrl = `https://www.linkedin.com/company/${companyData.company_shorthand_name}/`;
        companyData['company_linkedin_url'] = companyLinkedInUrl;
        setCompanySummaryData(companyData);

        let companyInsightsUrl = `/company/growth?universe_id=${universeId}&company_id=${companyData.company_id}&access_token=${accessToken}`;
        setCompanyInsightsUrl(companyInsightsUrl);
    };

    const handleCompanyChange = (event) => {
        setSelectedCompany(event.target.value);
        if (event.target.value !== 'All') {
            setCompanySummary(event.target.value);
            // find key for selected input in seriesDataCompanyMapper
            console.log(seriesDataCompanyMapper);
            let xyKey = Object.keys(seriesDataCompanyMapper).find(key => seriesDataCompanyMapper[key]['companyName'] === event.target.value);
            console.log(xyKey);
            setSelectedPointIndex(seriesDataCompanyMapper[xyKey]['index']);
        }
        else {
            setCompanySummaryData(null);
        }
        
    };

    useEffect(() => {
        setIsPageLoading(true);
        getUniverseData();
    }, []);

    useEffect(() => {
        try {
            let universeChartOptions = getUniverseChartOptions();
            const chart = Highcharts.chart('universe-chart-container', universeChartOptions);
            if (selectedCompany !== 'All' && selectedPointIndex !== null) {
                chart.series[0].data[selectedPointIndex].select();
            }
        }
        catch (err) {
            console.log(err);
        }

    }, [universeData, selectedChartView, selectedCompany, showAverage, showMedian]);

    if (isUnAuthorized) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. Please contact your admin for more details.</p>
            </div>
        );
    }
    else {
        return (
            <div>
                <div style={{textAlign: 'center'}}>
                    <h1>
                        Universe: {universeName}
                    </h1>
                </div>
                { isPageLoading ? (
                    <div style={{ textAlign: "center" }} ><ClipLoader/> </div>
                ) : (
                <div>
        
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{ fontSize: '20px', marginRight: '50px'}}>Total Annual Revenue: <b>{numberToCurrencyUSDFormatter(totalRevenue)}</b></div>
                    <div style={{ fontSize: '20px', marginLeft: '10px', marginRight:'50px'}}>Total Companies: <b>{totalCompanies}</b></div>
                    <div style={{ fontSize: '20px', marginLeft: '10px'}}>Total Employees: <b>{numberFormatter(totalEmployees)}</b></div>
                </div>
                <div className='chart-container' >
                    <div className="chart-container-left-universe">
                        <h3 style={{ textAlign: "center"}}>Filters</h3>
                        <br />
                        <div className="checkbox-group-container" >
                            { chartViewOptions.map((chartViewOption) => (
                            <div key={chartViewOptions.id} className="checkbox-container">
                                <input
                                    type="radio"
                                    id={chartViewOption.id}
                                    name="chartViewOption"
                                    // checked={selectedCheckbox === timeOption}
                                    defaultChecked={chartViewOption.label === "Employee Retention vs Net Headcount Growth"}
                                    onChange={() => handleChartViewChange(chartViewOption)}
                                />
                                <label htmlFor={chartViewOption.id}>{chartViewOption.label}</label>
                            </div>
                        ))}
                        </div>
                        <br />
                        <div style={{textAlign: 'center'}}>
                            <label>
                                Filter companies:&nbsp;
                                <br/>
                                <select value={selectedCompany} onChange={handleCompanyChange}>
                                    {
                                        companyOptions.map((value) => (
                                                <option key={value} value={value}>
                                                {value}
                                                </option>
                                            )
                                        )
                                    }
                                </select>
                            </label>
                        </div>
                        <br/>
                        <div>
                            <label>
                                <input type="checkbox" checked={showAverage} onChange={() => setShowAverage(!showAverage)} />
                                Show Average Overlay
                            </label>
                            <br/>
                            <label>
                                <input type="checkbox" checked={showMedian} onChange={() => setShowMedian(!showMedian)} />
                                Show Median Overlay
                            </label>
                        </div>
                        <br />
                    </div>
                    <div className="chart-container-middle-universe" id="universe-chart-container" >
                    </div>
                    <div className='chart-container-right-universe'>
                        <div className = 'chart-container-right-child' id="company-summary-container">
                            { companySummaryData ? (
                                <div>
                                    <h3 style={{ textAlign: "center"}}>{companySummaryData.company_name}</h3>
                                    <p>
                                        {companySummaryData.company_industry} <br/>
                                        {companySummaryData.company_headquarters_address} <br/>
                                        {companySummaryData.company_headquarters_country}
                                    </p>
                                    <p>
                                        <a href={companyInsightsUrl} target="_self" rel="noopener noreferrer" style={{ marginRight: '20px'}}>Insights</a> 
                                        <a href={companySummaryData.company_website} target="_blank" rel="noopener noreferrer" style={{ marginRight: '20px'}}>Website</a> 
                                        <a href={companySummaryData.company_linkedin_url} target="_blank" rel="noopener noreferrer">LinkedIn</a> <br/> <br/>
                                    </p>
                                    <p>
                                        Age (Years): <b>{companySummaryData.company_age !== null ? companySummaryData.company_age : '-'}</b> <br/> <br/>
                                        Employees (All): <b>{companySummaryData.company_employees_count_all !== null ? companySummaryData.company_employees_count_all : 'N/A'}</b> <br/> <br/>
                                        Employees (Public Profiles): <b>{companySummaryData.employees_count_public !== null ? companySummaryData.employees_count_public : 'N/A'}</b> <br/> <br/>
                                        Pct Public Profiles: <b>{companySummaryData.pct_public_profiles !== null ? (companySummaryData.pct_public_profiles * 100).toFixed(2) : 'N/A'}%</b> <br/> <br/>
                                        Pct Female: <b>{companySummaryData.current_avg_female_probability !== null ? (companySummaryData.current_avg_female_probability * 100).toFixed(2) : 'N/A'}%</b> <br/> <br/>
                                        Estimated Revenue: <b>{companySummaryData.estimated_revenue !== null ? numberToCurrencyUSDFormatter(companySummaryData.estimated_revenue) : '-'}</b> <br/> <br/>
                                        LTM Net Headcount Growth: <b>{companySummaryData.ltm_net_hc_growth !== null ? (companySummaryData.ltm_net_hc_growth * 100).toFixed(2) : 'N/A'}%</b> <br/> <br/>
                                        {/* Net HC Growth (3 years): <b>{(companySummaryData.metrics_3y_growth_est * 100).toFixed(2)}%</b> <br/> <br/> */}
                                        LTM Employee Hiring: <b>{companySummaryData.ltm_hiring_rate !== null ? (companySummaryData.ltm_hiring_rate * 100).toFixed(2) : 'N/A'}%</b> <br/> <br/>
                                        LTM Employee Attrition: <b>{companySummaryData.ltm_attrition_rate ?(companySummaryData.ltm_attrition_rate * 100).toFixed(2) : 'N/A'}%</b> <br/> <br/>
                                        {/* Emp Attrition (3 years): <b>{(companySummaryData.metrics_3y_attrition_est * 100).toFixed(2)}%</b> <br/> <br/> */}
                                        LTM Employee Retention: <b>{companySummaryData.ltm_retention_rate !== null ?(companySummaryData.ltm_retention_rate * 100).toFixed(2) : 'N/A'}%</b> <br/> <br/>
                                        LTM Growth Productivity: <b>{companySummaryData.ltm_growth_productivity !== null ?(companySummaryData.ltm_growth_productivity * 100).toFixed(2) : 'N/A'}%</b> <br/> <br/>
                                        {/* Emp Retention (3 years): <b>{(companySummaryData.metrics_3y_retention_est * 100).toFixed(2)}%</b> <br/> <br/> */}
                                        Average Company Tenure (Years): <b>{companySummaryData.avg_company_tenure_years !== null ? companySummaryData.avg_company_tenure_years.toFixed(1) : 'N/A'}</b>  <br/> <br/>
                                        Average Career Tenure (Years): <b>{companySummaryData.avg_career_tenure_years !== null ? companySummaryData.avg_career_tenure_years.toFixed(1) : 'N/A'}</b> <br/> <br/>
                                        {/* Hiring Density (3 years): <b>{(companySummaryData.metrics_3yr_hiring_density * 100).toFixed(2)}%</b> <br/> <br/>
                                        Hiring Density (5 years): <b>{(companySummaryData.metrics_5yr_hiring_density * 100).toFixed(2)}%</b> <br/> <br/> */}
                                    </p>
                                    <p>
                                        Total Funding Raised: <b>{companySummaryData.total_funding_raised > 0  && companySummaryData.total_funding_raised !== null ? numberToCurrencyUSDFormatter(companySummaryData.total_funding_raised) : '-'}</b> <br/> <br/>
                                        Last Funding Date: <b>{companySummaryData.last_funding_date !== null ? companySummaryData.last_funding_date : '-'}</b> <br/> <br/>
                                        Last Funding Raised: <b>{companySummaryData.last_funding_total !== null && companySummaryData.last_funding_total > 0 ? numberToCurrencyUSDFormatter(companySummaryData.last_funding_total) : '-'}</b> <br/> <br/> 
                                        Last Funding Valuation: <b>{companySummaryData.last_funding_valuation !== null && companySummaryData.last_funding_valuation > 0 ? numberToCurrencyUSDFormatter(companySummaryData.last_funding_valuation) : '-'}</b> <br/> <br/>
                                        Last Funding Round: <b>{companySummaryData.last_funding_round !== null && companySummaryData.last_funding_round > 0 ? numberToCurrencyUSDFormatter(companySummaryData.last_funding_round) : '-'}</b> <br/> <br/>
                                    </p>
                                </div>
                                
                            ) : (
                                <p> Click on any data point or filter companies to view details </p>
                            )}
                        </div>
                    </div>
                </div>
                <div style={{ margin:'0 auto', textAlign: 'center'}}>
                    <DownloadButton 
                        data={universeData} 
                        buttonText={ 'Download Universe Data' }
                        fileName={ `${universeName}_data.csv` }
                    />
                </div>
                <div style={{ margin: '10px'}}>
                    Notes:
                    <ul>
                        <li>LTM = Last Twelve Months</li>
                        <li>HC = Headcount</li>
                        <li>Public Profiles = Public profiles on LinkedIn</li>
                        <li>
                            All computed metrics (HC, Tenure, Hiring Density) are based on public profiles only. See the 'Pct Public Profiles' metric to understand the coverage of public profiles compared to all profiles.</li>
                    </ul>
                </div>
                <br/>
                </div> )}
            </div>
        );
    }
}

export default UniverseChartComponent;
