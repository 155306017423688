import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { useSearchParams, useLocation, NavLink } from 'react-router-dom';
import { customSort, getApiServrUrl } from '../utils/utils';
import { lineColors, lineStyles } from '../utils/constants';
import { DownloadButton } from './download_button';


const UniverseCompanyTeamGrowthChartComponent = () => {
    // const [teamsDeptData, setTeamsDeptData] = useState([]);
    // const [teamsSeniorityData, setTeamsSeniorityData] = useState([]);
    const [teamsGrowthData, setTeamsGrowthData] = useState([]);

    const [geo1Values, setGeo1Values] = useState([]);
    const [selectedGeo1, setSelectedGeo1] = useState('option1');
    const [deptValues, setDeptValues] = useState([]);
    const [selectedDept, setSelectedDept] = useState('All');
    const [seniorityValues, setSeniorityValues] = useState([]);
    const [selectedSeniority, setSelectedSeniority] = useState('All');
    
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [deptDistDataDate, setDeptDistDataDate] = useState(process.env.REACT_APP_DATA_VERSION_DATE);
    const [seniorityDistDataDate, setSeniorityDistDataDate] = useState(process.env.REACT_APP_DATA_VERSION_DATE);
    // const [companyName, setCompanyName] = useState('');
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const companyName = location.state && location.state.companyName;
    const universeId = searchParams && searchParams.get('universe_id') || location.state && location.state.universeId;
    const companyId = searchParams && searchParams.get('company_id') || location.state && location.state.companyId;
    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.accessToken;
    
    const universeSummaryPath = `/universe/summary?universe_id=${universeId}&access_token=${accessToken}`;
    const companyGrowthPath = `/universe/company/growth?universe_id=${universeId}&company_id=${companyId}&access_token=${accessToken}`;
    const talentFlowPath = `/universe/company/talent?universe_id=${universeId}&company_id=${companyId}&access_token=${accessToken}`;

    // const getDepartmentGrowthData = async() => {
    //     let apiUrl = getApiServrUrl();
    //     apiUrl += process.env.REACT_APP_API_TEAM_GROWTH_DEPT_ENDPOINT;
    //     console.log(`CompanyTeamGrowth: Fetching dept growth data from ${apiUrl}...`);

    //     let data = {
    //         "company_ids": [parseInt(companyId)],
    //     }
    //     let config = {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + accessToken,
    //         }
    //     };
    //     try {
    //         const response = await axios.post(apiUrl, data, config);

    //         if (response.data.data.headcount.length !== 0) {
    //             const jsonData = JSON.parse(response.data.data.headcount);
    //             let geo1Values = [...new Set(jsonData.map((item) => item.geo1))];
    //             // option1Values = option1Values.filter((item) => item !== null);
    //             setGeo1Values(geo1Values);
    //             setSelectedGeo1('All');

    //             let deptValues = [... new Set(jsonData.map((item) => item.department))];
    //             //deptValues.push('All');
    //             setDeptValues(deptValues);
    //             setSelectedDept('All');

    //             setTeamsDeptData(jsonData);

    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    //     setIsPageLoading(false);
    // };

    // const getSeniorityGrowthData = async() => {
    //     let apiUrl = getApiServrUrl();
    //     apiUrl += process.env.REACT_APP_API_TEAM_GROWTH_SENIORITY_ENDPOINT;
    //     console.log(`CompanyTeamGrowth: Fetching seniority growth data from ${apiUrl}...`);
        
    //     let data = {
    //         "company_ids": [parseInt(companyId)],
    //     }
    //     let config = {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + accessToken,
    //         }
    //     };
    //     try {
    //         const response = await axios.post(apiUrl, data, config);

    //         if (response.data.data.headcount.length !== 0) {
    //             const jsonData = JSON.parse(response.data.data.headcount);
    //             let geo1Values = [...new Set(jsonData.map((item) => item.geo1))];
    //             // option1Values = option1Values.filter((item) => item !== null);
    //             setGeo1Values(geo1Values);
    //             setSelectedGeo1('All');

    //             let seniorityValues = [... new Set(jsonData.map((item) => item.seniority))];
    //             // seniorityValues.push('All');
    //             setSeniorityValues(seniorityValues);
    //             setSelectedSeniority('All');

    //             setTeamsSeniorityData(jsonData);
                
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    //     setIsPageLoading(false);
    // }

    const getTeamsGrowthData = async() => {
        let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_TEAM_GROWTH_ENDPOINT;
        console.log(`CompanyTeamGrowth: Fetching team growth data from ${apiUrl}...`);

        let data = {
            "company_ids": [parseInt(companyId)],
        }
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        };
        try {
            const response = await axios.post(apiUrl, data, config);

            if (response.data.data.headcount.length !== 0) {
                const jsonData = JSON.parse(response.data.data.headcount);
                let geo1Values = [...new Set(jsonData.map((item) => item.geo1))];
                setGeo1Values(geo1Values);
                setSelectedGeo1('All');

                let deptValues = [... new Set(jsonData.map((item) => item.department))];
                const sortedDeptValues = customSort(deptValues, ['All', 'Sales', 'Product']);
                setDeptValues(sortedDeptValues);
                setSelectedDept('All');

                let seniorityValues = [... new Set(jsonData.map((item) => item.seniority))];
                const sortedSeniorityOrder = [
                    "All",
                    "Individual Contributor",
                    "Advisor / Board",
                    "Executive",
                    "Leadership",
                    "Management",
                    "Student / Intern",
                    "Unsure"
                ];
                const sortedSeniorityValues = customSort(seniorityValues, sortedSeniorityOrder);
                setSeniorityValues(sortedSeniorityValues);
                setSelectedSeniority('All');

                setTeamsGrowthData(jsonData);

            }
        } catch (err) {
            console.log(err);
        }
        setIsPageLoading(false);
    };

    const handleGeo1Change = (event) => {
        setSelectedGeo1(event.target.value);
    };

    const handleDeptChange = (event) => {
        setSelectedDept(event.target.value);
    };

    const handleSeniorityChange = (event) => {
        setSelectedSeniority(event.target.value);
    };

    //////////////////////////////
    // Headcount By Department Line Chart //
    function getHeadcountByDepartmentOptions() {
        let filteredTeamsDepartmentData = teamsGrowthData.filter((item) => {
            return item.geo1 === selectedGeo1 && item.seniority == selectedSeniority && item.department !== 'All'; // Filtering out 'All' here
        });
        // filter out selected dept from the dropdown
        if (selectedDept !== 'All') {
            filteredTeamsDepartmentData = filteredTeamsDepartmentData.filter((item) => {
                return item.department === selectedDept;
            });
        }
        
        const deptDataMap = {};
        const dataSeries = [];
        for (let i=0; i<filteredTeamsDepartmentData.length; i++) {
            if (deptDataMap.hasOwnProperty(filteredTeamsDepartmentData[i].department)) {
                const data = [filteredTeamsDepartmentData[i].the_date, filteredTeamsDepartmentData[i].total_headcount];
                deptDataMap[filteredTeamsDepartmentData[i].department].push(data);
                continue;
            }
            deptDataMap[filteredTeamsDepartmentData[i].department] = [];
        };

        const legendOrder = ['All', 'Sales', 'Product'];

        // Sort based on legendOrder, then alphabetically, and 'Unsure' is always last
        const sortedDeptDataMap = Object.entries(deptDataMap)
            .sort(([keyA], [keyB]) => {
                if (keyA === 'Unsure') return 1;
                if (keyB === 'Unsure') return -1;
                if (keyA === keyB) return 0;
                if (legendOrder.includes(keyA) && legendOrder.includes(keyB)) {
                    return legendOrder.indexOf(keyA) - legendOrder.indexOf(keyB);
                }
                if (legendOrder.includes(keyA)) return -1;
                if (legendOrder.includes(keyB)) return 1;
                return keyA.localeCompare(keyB);
            })
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        for (let key in sortedDeptDataMap) {
            dataSeries.push({
                name: 'Headcount: ' +key,
                data: sortedDeptDataMap[key],
                type: 'spline',
                color: lineColors[key],
            });
        };

		return {
			chart: {
				zoomType: 'x',
                type: 'spline',
                height: 435
			},
			title: {
				text: 'Public Profiles by Department' ,
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime'
			},
			yAxis: {
				title: {
					text: 'Total Public Profile Headcount'
				},
			},
            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'top',
                y: 50,
            },
			
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                console.log(event);
                            }
                        }
                    }
                },
            },
	
		};
		
	};

    //////////////////////////////
    // Headcount By Seniority Line Chart //
    function getHeadcountBySeniorityOptions() {
        let filteredTeamsSeniorityData = teamsGrowthData.filter((item) => {
            return item.geo1 === selectedGeo1 && item.department === selectedDept && item.seniority !== 'All'; // Filtering out 'All' here
        });
        // filter out selected seniority from the dropdown
        if (selectedSeniority !== 'All') {
            filteredTeamsSeniorityData = filteredTeamsSeniorityData.filter((item) => {
                return item.seniority === selectedSeniority;
            });
        }

        const seniorityDataMap = {};
        const dataSeries = [];
        for (let i=0; i<filteredTeamsSeniorityData.length; i++) {
            if (seniorityDataMap.hasOwnProperty(filteredTeamsSeniorityData[i].seniority)) {
                const data = [filteredTeamsSeniorityData[i].the_date, filteredTeamsSeniorityData[i].total_headcount];
                seniorityDataMap[filteredTeamsSeniorityData[i].seniority].push(data);
                continue;
            }
            seniorityDataMap[filteredTeamsSeniorityData[i].seniority] = [];
        };

        const legendOrder = [
            "Advisor / Board",
            "Executive",
            "Leadership",
            "Management",
            "Individual Contributor",
            "Student / Intern",
            "Unsure"
        ];
        
        const sortedSeniorityDataMaps = Object.entries(seniorityDataMap)
            .sort(([keyA], [keyB]) => {
                const indexA = legendOrder.indexOf(keyA);
                const indexB = legendOrder.indexOf(keyB);
                return indexA - indexB;
            })
            .map(([key, value]) => ({
                name: "Headcount: " + key,
                data: value,
                type: 'spline',
                color: lineColors[key],
            }));
        
        dataSeries.push(...sortedSeniorityDataMaps);
        
        console.log(sortedSeniorityDataMaps);


        return {
			chart: {
				zoomType: 'x',
                type: 'spline',
                height: 435
			},
			title: {
				text: 'Public Profiles by Seniority' ,
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime'
			},
			yAxis: {
				title: {
					text: 'Total Public Profile Headcount'
				},
			},
            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'top',
                y: 50,
            },
			
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },
	
		};
    };

    //////////////////////////////
    // Headcount By Geo Line Chart //
    function getHeadcountByGeoOptions () {
        let filteredTeamsGeoData = teamsGrowthData.filter((item) => {
            return item.geo1 !== 'All' && item.department === selectedDept && item.seniority === selectedSeniority; // Filtering out 'All' here
        });
        // filter out selected geo1 from the dropdown
        if (selectedGeo1 !== 'All') {
            filteredTeamsGeoData = filteredTeamsGeoData.filter((item) => {
                return item.geo1 === selectedGeo1;
            });
        }

        const geoDataMap = {};
        const dataSeries = [];
        for (let i=0; i<filteredTeamsGeoData.length; i++) {
            if (geoDataMap.hasOwnProperty(filteredTeamsGeoData[i].geo1)) {
                const data = [filteredTeamsGeoData[i].the_date, filteredTeamsGeoData[i].total_headcount];
                geoDataMap[filteredTeamsGeoData[i].geo1].push(data);
                continue;
            }
            geoDataMap[filteredTeamsGeoData[i].geo1] = [];
        };

        const legendOrder = [ "All", "North America", "Europe, Middle East & Africa" ];
        
        const sortedGeoDataMap = Object.entries(geoDataMap)
            .sort(([keyA], [keyB]) => {
                if (keyA === 'N/A') return 1;
                if (keyB === 'N/A') return -1;
                if (keyA === keyB) return 0;
                if (legendOrder.includes(keyA) && legendOrder.includes(keyB)) {
                    return legendOrder.indexOf(keyA) - legendOrder.indexOf(keyB);
                }
                if (legendOrder.includes(keyA)) return -1;
                if (legendOrder.includes(keyB)) return 1;
                return keyA.localeCompare(keyB);
            })
            .map(([key, value]) => ({
                name: "Headcount: " + key,
                data: value,
                type: 'spline',
                color: lineColors[key],
            }));
        
        dataSeries.push(...sortedGeoDataMap);
        
        console.log(sortedGeoDataMap);

        return {
			chart: {
				zoomType: 'x',
                type: 'spline',
                height: 435
			},
			title: {
				text: 'Public Profiles by Region' ,
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime'
			},
			yAxis: {
				title: {
					text: 'Total Public Profile Headcount'
				},
			},
            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'top',
                y: 50,
            },
			
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },
	
		};
    };

    //////////////////////////////
    // Department Distribution Pie Chart //
    function getDeptPieChartOptions() {
        let date = new Date(deptDistDataDate);
        let month = date.getMonth();
        let year = date.getFullYear();

        let filteredTeamsDepartmentData = teamsGrowthData.filter((item) => {
            let itemDate = new Date(item.the_date);
            let dateCondition = itemDate.getMonth() + 1 === month && itemDate.getFullYear() === year
            return item.geo1 === selectedGeo1 && item.seniority === selectedSeniority && dateCondition && item.department !== 'All'; // Filter out 'All' from the dataseries;
        });

        // filter out selected department from the dropdown
        if (selectedDept !== 'All') {
            filteredTeamsDepartmentData = filteredTeamsDepartmentData.filter((item) => {
                return item.department === selectedDept;
            });
        }

        const deptDataMap = {};
        let totalHeadcount = 0;
        const dataSeries = [];
        for (let i=0; i<filteredTeamsDepartmentData.length; i++) {
            totalHeadcount += filteredTeamsDepartmentData[i].total_headcount;
            deptDataMap[filteredTeamsDepartmentData[i].department] = filteredTeamsDepartmentData[i].total_headcount;
        };
        
        // Filter out 'All' from the dataseries
        for (let key in deptDataMap) {
            dataSeries.push({
                name: key,
                y: (deptDataMap[key] / totalHeadcount) * 100,
                color: lineColors[key],
            });
        }

        // Sort the data series in descending order based on 'y' value, but keep "Unsure" at the end
        dataSeries.sort((a, b) => {
            // If a is "Unsure", it should come last - return 1
            if (a.name === "Unsure") return 1;
            // If b is "Unsure", it should come last - return -1
            if (b.name === "Unsure") return -1;
            // Otherwise, sort normally
            return b.y - a.y;
        });

        return {
            chart: {
                type: 'pie',
                // marginRight: 170,
            },
            title: {
                text: `Department Distribution ${date.toLocaleString('default', { month: 'long' })} ${year}`,
            },
            subtitle: {
				text: 'Click on the left chart to view distribution for any particular time period',
				align: 'left'
			},
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },

            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: '80%',
                    dataLabels: {
                        enabled: true,
                        distance: -40,  // Negative values pull the labels closer to the pie, positive values push them further away
                        formatter: function() {
                            // Check if the current point is in the top 5
                            if (this.point.index < 5) {
                                return this.point.name;
                            }
                        }
                        
                    },
                    // showInLegend: true,
                },
            },

            series: [{
                name: 'Headcount dist',
                colorByPoint: true,
                data: dataSeries,
            }],

            credits: {
				enabled: false
			},
        };

    };

    //////////////////////////////
    // Seniority Distribution Pie Chart //
    function getSeniorityPieChartOptions() {
        let date = new Date(seniorityDistDataDate);
        let month = date.getMonth();
        let year = date.getFullYear();

        let filteredTeamsSeniorityData = teamsGrowthData.filter((item) => {
            let itemDate = new Date(item.the_date);
            let dateCondition = itemDate.getMonth() + 1 === month && itemDate.getFullYear() === year
            return item.geo1 === selectedGeo1 && item.department === selectedDept && dateCondition && item.seniority !== 'All'; // Filter out 'All' from the dataseries
        });

        // filter out selected seniority from the dropdown
        if (selectedSeniority !== 'All') {
            filteredTeamsSeniorityData = filteredTeamsSeniorityData.filter((item) => {
                return item.seniority === selectedSeniority;
            });
        }

        const seniorityDataMap = {};
        let totalHeadcount = 0;
        const dataSeries = [];
        for (let i=0; i<filteredTeamsSeniorityData.length; i++) {
            totalHeadcount += filteredTeamsSeniorityData[i].total_headcount;
            seniorityDataMap[filteredTeamsSeniorityData[i].seniority] = filteredTeamsSeniorityData[i].total_headcount;
        };
        
        for (let key in seniorityDataMap) {
            dataSeries.push({
                name: key,
                y: (seniorityDataMap[key] / totalHeadcount) * 100,
                color: lineColors[key],
            });
        }

        // Sort the data series in descending order based on 'y' value, but keep "Unsure" at the end
        dataSeries.sort((a, b) => {
            // If a is "Unsure", it should come last - return 1
            if (a.name === "Unsure") return 1;
            // If b is "Unsure", it should come last - return -1
            if (b.name === "Unsure") return -1;
            // Otherwise, sort normally
            return b.y - a.y;
        });
  
        return {
            chart: {
                type: 'pie'
            },
            title: {
                text: `Seniority Distribution ${date.toLocaleString('default', { month: 'long' })} ${year}`,
            },
            subtitle: {
				text: 'Click on the left chart to view distribution for any particular time period',
				align: 'left'
			},
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },

            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: '80%',
                    dataLabels: {
                        enabled: true,
                        distance: -50,  // Negative values pull the labels closer to the pie, positive values push them further away
                        formatter: function() {
                            // Check if the current point is in the top 5
                            if (this.point.index < 3) {
                                return this.point.name;
                            }
                        }
                        
                    },
                    // showInLegend: true,
                },
            },

            series: [{
                name: 'Headcount %',
                colorByPoint: true,
                data: dataSeries,
            }],

            // legend: {
            //     layout: 'vertical',
            //     align: 'right',
            //     verticalAlign: 'middle',
            //     itemMarginTop: 20,
            // },

            credits: {
				enabled: false
			},
        };

    };

    //////////////////////////////
    // Team Growth By Department Line Chart //
    function getTeamGrowthBySeniorityOptions() { 
        const filteredTeamsSeniorityData = teamsGrowthData.filter((item) => {
            if (selectedSeniority === 'All') {
                return item.geo1 === selectedGeo1 && item.department === selectedDept;
            }
            return item.geo1 === selectedGeo1 && item.seniority == selectedSeniority && item.department === selectedDept;
        });

        const seniorityDataMap = {};
        const dataSeries = [];
        const excludeSeniorities = ['Student/Intern'];
        for (let i=0; i<filteredTeamsSeniorityData.length; i++) {
            const teamData = filteredTeamsSeniorityData[i];
            if (seniorityDataMap.hasOwnProperty(filteredTeamsSeniorityData[i].seniority)) {
                seniorityDataMap[filteredTeamsSeniorityData[i].seniority].push({
                    the_date: teamData.the_date, 
                    ltm_addition_rate: parseFloat((teamData.ltm_addition_rate * 100).toFixed(2)),
                    ltm_attrition_rate: parseFloat((teamData.ltm_attrition_rate * 100).toFixed(2)),
                    ltm_net_hc_growth: parseFloat((teamData.ltm_net_hc_growth * 100).toFixed(2)),
                    ltm_growth_productivity: parseFloat((teamData.ltm_growth_productivity * 100).toFixed(2)),
            });
                continue;
            }
            seniorityDataMap[filteredTeamsSeniorityData[i].seniority] = [];
        };



        const legendOrder = [
            "All",
            "Individual Contributor",
            "Advisor / Board",
            "Executive",
            "Leadership",
            "Management",
            "Student / Intern",
            "Unsure"
        ];
        
        function getFriendlyMetricName(metric) {
            switch (metric) {
                case 'ltm_net_hc_growth':
                    return 'LTM Net Growth';
                case 'ltm_attrition_rate':
                    return 'LTM Attrition';
                case 'ltm_addition_rate':
                    return 'LTM Hiring';
                case 'ltm_growth_productivity':
                    return 'LTM Growth Prod';
                default:
                    return metric; // return the metric as is if no friendly name is defined
            }
        }

        const sortedSeniorityDataMaps = Object.entries(seniorityDataMap)
            .sort(([keyA], [keyB]) => {
                const indexA = legendOrder.indexOf(keyA);
                const indexB = legendOrder.indexOf(keyB);
                return indexA - indexB;
            })
            .reduce((acc, [key, value]) => {
                ['ltm_addition_rate', 'ltm_attrition_rate', 'ltm_net_hc_growth', 'ltm_growth_productivity'].forEach(metric => {
                // Define the conditions for default visibility
                    const isVisibleSeniority = (key === 'All' || key === 'Individual Contributor' || key === selectedSeniority);
                    const isVisible = (
                        (metric === 'ltm_addition_rate' && isVisibleSeniority ||
                        (metric === 'ltm_attrition_rate' && isVisibleSeniority))
                    );
                    acc.push({
                        name: `${getFriendlyMetricName(metric)}: ${key}`,
                        data: value.map(item => [new Date(item.the_date).getTime(), item[metric]]),
                        type: 'spline',
                        color: lineColors[key],
                        dashStyle: lineStyles[getFriendlyMetricName(metric)],
                        visible: isVisible
                    });
                });
                return acc;
            }, []);

        dataSeries.push(...sortedSeniorityDataMaps);
        
        console.log(sortedSeniorityDataMaps);

        return {
			chart: {
				zoomType: 'x',
                type: 'spline',
                height: 435
			},
			title: {
				text: 'Team Growth By Seniority',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime'
			},
			yAxis: {
				labels: {
					format: '{value}%'
				}
			},
            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'top',
                y: 50,
            },
			
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                console.log(event);
                                let date = new Date(event.point.x);
                                let dateStr = '' + date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
                                console.log(dateStr);
                                setSeniorityDistDataDate(dateStr);
                            }
                        }
                    }
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};

    };

    //////////////////////////////
    // Team Growth By Seniority Line Chart //
    function getTeamGrowthByDepartmentOptions() { 
        const filteredTeamsDepartmentData = teamsGrowthData.filter((item) => {
            if (selectedDept == 'All') {
                return item.geo1 === selectedGeo1 && item.seniority == selectedSeniority;
            }
            return item.geo1 === selectedGeo1 && item.department == selectedDept && item.seniority == selectedSeniority;
        });
        
        const deptDataMap = {};
        const dataSeries = [];
        const excludeDepts = ['Student', 'Intern'];
        for (let i=0; i<filteredTeamsDepartmentData.length; i++) {
            const teamData = filteredTeamsDepartmentData[i];
            if (deptDataMap.hasOwnProperty(teamData.department)) {
                deptDataMap[teamData.department].push({
                    the_date: teamData.the_date, 
                    ltm_addition_rate: parseFloat((teamData.ltm_addition_rate * 100).toFixed(2)),
                    ltm_attrition_rate: parseFloat((teamData.ltm_attrition_rate * 100).toFixed(2)),
                    ltm_net_hc_growth: parseFloat((teamData.ltm_net_hc_growth * 100).toFixed(2)),
                    ltm_growth_productivity: parseFloat((teamData.ltm_growth_productivity * 100).toFixed(2)),
                });
                continue;
            }
            deptDataMap[teamData.department] = [];
        };
    
        const legendOrder = ['All', 'Sales', 'Product'];
    
        // Sort based on legendOrder, then alphabetically, and 'Unsure' is always last
        const sortedDeptDataMap = Object.entries(deptDataMap)
            .sort(([keyA], [keyB]) => {
                if (keyA === 'Unsure') return 1;
                if (keyB === 'Unsure') return -1;
                if (keyA === keyB) return 0;
                if (legendOrder.includes(keyA) && legendOrder.includes(keyB)) {
                    return legendOrder.indexOf(keyA) - legendOrder.indexOf(keyB);
                }
                if (legendOrder.includes(keyA)) return -1;
                if (legendOrder.includes(keyB)) return 1;
                return keyA.localeCompare(keyB);
            })
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});
    
        const defaultVisibleSeries = ['All', 'Sales'];

        function getFriendlyMetricName(metric) {
            switch (metric) {
                case 'ltm_net_hc_growth':
                    return 'LTM Net Growth';
                case 'ltm_attrition_rate':
                    return 'LTM Attrition';
                case 'ltm_addition_rate':
                    return 'LTM Hiring';
                case 'ltm_growth_productivity':
                    return 'LTM Growth Prod';
                default:
                    return metric; // return the metric as is if no friendly name is defined
            }
        }
        
        for (let key in sortedDeptDataMap) {
            ['ltm_addition_rate', 'ltm_attrition_rate', 'ltm_net_hc_growth', 'ltm_growth_productivity'].forEach(metric => {
                // Define the conditions for default visibility
                const isVisibleDept = (key === 'All' || key === 'Sales' || key === selectedDept);
                const isVisible = (
                    (metric === 'ltm_addition_rate' && isVisibleDept ||
                    (metric === 'ltm_attrition_rate' && isVisibleDept))
                );
                dataSeries.push({
                    name: `${getFriendlyMetricName(metric)}: ${key}`,
                    data: sortedDeptDataMap[key].map((item) => [new Date(item.the_date).getTime(), item[metric]]),
                    type: 'spline',
                    color: lineColors[key],
                    dashStyle: lineStyles[getFriendlyMetricName(metric)],
                    visible: isVisible
                });
            });            
        }
            
        console.log('Printing sortedDeptDataMap')
        console.log(sortedDeptDataMap);

        return {
			chart: {
				zoomType: 'x',
                type: 'spline',
                height: 435
			},
			title: {
				text: 'Team Growth By Department',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime'
			},
			yAxis: {
				labels: {
					format: '{value}%'
				}
			},
            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'top',
                y: 50,
            },
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                console.log(event.point.x);
                                let date = new Date(event.point.x);
                                console.log(date);
                                let dateStr = '' + date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
                                // updateTeamsSeniorityData(dateStr);
                                console.log(dateStr);
                                setDeptDistDataDate(dateStr);
                            }
                        }
                    }
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};

    };

    //////////////////////////////
    // Team Growth By Geo Line Chart //
    function getTeamGrowthByGeoOptions() {
        const filteredTeamsGeoData = teamsGrowthData.filter((item) => {
            if (selectedGeo1 == 'All') {
                return item.department === selectedDept && item.seniority == selectedSeniority;
            }
            return item.geo1 === selectedGeo1 && item.department == selectedDept && item.seniority == selectedSeniority;
        });
        
        const geoDataMap = {};
        const dataSeries = [];
        for (let i=0; i<filteredTeamsGeoData.length; i++) {
            const geoData = filteredTeamsGeoData[i];
            if (geoDataMap.hasOwnProperty(geoData.geo1)) {
                geoDataMap[geoData.geo1].push({
                    the_date: geoData.the_date, 
                    ltm_addition_rate: parseFloat((geoData.ltm_addition_rate * 100).toFixed(2)),
                    ltm_attrition_rate: parseFloat((geoData.ltm_attrition_rate * 100).toFixed(2)),
                    ltm_net_hc_growth: parseFloat((geoData.ltm_net_hc_growth * 100).toFixed(2)),
                    ltm_growth_productivity: parseFloat((geoData.ltm_growth_productivity * 100).toFixed(2)),
                });
                continue;
            }
            geoDataMap[geoData.geo1] = [];
        };

        const legendOrder = ['All', 'North America', 'Europe, Middle East & Africa'];
    
        // Sort based on legendOrder, then alphabetically, and 'N/A' is always last
        const sortedGeoDataMap = Object.entries(geoDataMap)
            .sort(([keyA], [keyB]) => {
                if (keyA === 'N/A') return 1;
                if (keyB === 'N/A') return -1;
                if (keyA === keyB) return 0;
                if (legendOrder.includes(keyA) && legendOrder.includes(keyB)) {
                    return legendOrder.indexOf(keyA) - legendOrder.indexOf(keyB);
                }
                if (legendOrder.includes(keyA)) return -1;
                if (legendOrder.includes(keyB)) return 1;
                return keyA.localeCompare(keyB);
            })
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        function getFriendlyMetricName(metric) {
            switch (metric) {
                case 'ltm_net_hc_growth':
                    return 'LTM Net Growth';
                case 'ltm_attrition_rate':
                    return 'LTM Attrition';
                case 'ltm_addition_rate':
                    return 'LTM Hiring';
                case 'ltm_growth_productivity':
                    return 'LTM Growth Prod';
                default:
                    return metric; // return the metric as is if no friendly name is defined
            }
        }
        
        for (let key in sortedGeoDataMap) {
            ['ltm_addition_rate', 'ltm_attrition_rate', 'ltm_net_hc_growth', 'ltm_growth_productivity'].forEach(metric => {
                // Define the conditions for default visibility
                const isVisibleGeo = (key === 'All' || key === 'North America' || key === selectedGeo1);
                const isVisible = (
                    (metric === 'ltm_addition_rate' && isVisibleGeo ||
                    (metric === 'ltm_attrition_rate' && isVisibleGeo))
                );
                dataSeries.push({
                    name: `${getFriendlyMetricName(metric)}: ${key}`,
                    data: sortedGeoDataMap[key].map((item) => [new Date(item.the_date).getTime(), item[metric]]),
                    type: 'spline',
                    color: lineColors[key],
                    dashStyle: lineStyles[getFriendlyMetricName(metric)],
                    visible: isVisible
                });
            });            
        }
            
        console.log('Printing sortedGeoDataMap')
        console.log(sortedGeoDataMap);

        return {
			chart: {
				zoomType: 'x',
                type: 'spline',
                height: 435
			},
			title: {
				text: 'Team Growth By Region',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime'
			},
			yAxis: {
				labels: {
					format: '{value}%'
				}
			},
            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'top',
                y: 50,
            },
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};
    };

    useEffect(() => {
        setIsPageLoading(true);
        // getDepartmentGrowthData();
        // getSeniorityGrowthData();
        getTeamsGrowthData();
    }, []);

    useEffect(() => {
        let teamGrowthByDepartmentOptions = getTeamGrowthByDepartmentOptions();
        Highcharts.chart('team-by-dept-chart-container', teamGrowthByDepartmentOptions);

        let teamGrowthBySeniorityChartOptions = getTeamGrowthBySeniorityOptions();
        Highcharts.chart('team-by-seniority-chart-container', teamGrowthBySeniorityChartOptions);

        let teamGrowthByGeoChartOptions = getTeamGrowthByGeoOptions();
        Highcharts.chart('team-by-geo-chart-container', teamGrowthByGeoChartOptions);

        let headcountByDeptChartOptions = getHeadcountByDepartmentOptions();
        Highcharts.chart('headcount-by-dept-chart-container', headcountByDeptChartOptions);

        let headcountBySeniorityChartOptions = getHeadcountBySeniorityOptions();
        Highcharts.chart('headcount-by-seniority-chart-container', headcountBySeniorityChartOptions);

        let headcountByGeoChartOptions = getHeadcountByGeoOptions();
        Highcharts.chart('headcount-by-geo-chart-container', headcountByGeoChartOptions);

        let deptPieChartOptions = getDeptPieChartOptions();
        Highcharts.chart('dept-pie-chart-container', deptPieChartOptions);

        let seniorityPieChartOptions = getSeniorityPieChartOptions();
        Highcharts.chart('seniority-pie-chart-container', seniorityPieChartOptions);

    }, [teamsGrowthData, selectedGeo1, selectedDept, selectedSeniority]);

    useEffect(() => {
        let deptPieChartOptions = getDeptPieChartOptions();
        Highcharts.chart('dept-pie-chart-container', deptPieChartOptions);

    }, [deptDistDataDate]);

    useEffect(() => {
        let seniorityPieChartOptions = getSeniorityPieChartOptions();
        Highcharts.chart('seniority-pie-chart-container', seniorityPieChartOptions);
        
    }, [seniorityDistDataDate]);

    return (
        <div>
            <div style={{ display: 'flex'}}>
                <div style={{display: 'flex', justifyContent: 'flex-start', padding: '10px'}}>
                    <NavLink
                        to={universeSummaryPath}
                        state={{universeId: universeId, accessToken: accessToken}}
                    > Universe Summary
                    </NavLink>
                </div>
                <div style={{display: 'flex', flex: '1', justifyContent: 'flex-end', padding: '10px'}}>
                    <NavLink 
                        to={companyGrowthPath}
                        state={{universeId: universeId, companyId: companyId, accessToken: accessToken, companyName: companyName}}
                    > Team Growth 
                    </NavLink>
                    <div style={{ padding: '10px'}}></div>
                    <NavLink 
                        to={talentFlowPath}
                        state={{universeId: universeId, companyId: companyId, accessToken: accessToken, companyName: companyName}}
                    > Talent Flow
                    </NavLink>
                </div>
            </div>

            <div style={{textAlign: 'center'}}>
                <h1 style={{marginTop: '0px', marginBottom: '10px'}}>
                    Team Growth: {companyName}
                </h1>
            </div>
            { isPageLoading ? (
                <div style={{ textAlign: "center" }} ><ClipLoader/> </div>
            ) : (
            <div>
            <div style={{textAlign: 'center'}}>
                <label>
                    Region:&nbsp;
                    <select value={selectedGeo1} onChange={handleGeo1Change}>
                        {
                            geo1Values.map((value) => (
                                    <option key={value} value={value}>
                                    {value}
                                    </option>
                                )
                            )
                        }
                    </select>
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                    Department:&nbsp;
                    <select value={selectedDept} onChange={handleDeptChange}>
                        {
                            deptValues.map((value) => (
                                    <option key={value} value={value}>
                                    {value}
                                    </option>
                                )
                            )
                        }
                    </select>
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                    Seniority:&nbsp;
                    <select value={selectedSeniority} onChange={handleSeniorityChange}>
                        {
                            seniorityValues.map((value) => (
                                    <option key={value} value={value}>
                                    {value}
                                    </option>
                                )
                            )
                        }
                    </select>
                </label>
            </div>
            <br/>
            <div className='chart-container'>
                <div className="chart-container-left-hc" id="team-by-dept-chart-container"></div>
                <div className="chart-container-right-hc" id="dept-pie-chart-container"></div>
            </div>
            <div className='chart-container'>
                <div className="chart-container-left-hc" id="team-by-seniority-chart-container"></div>
                <div className="chart-container-right-hc" id="seniority-pie-chart-container"></div>
            </div>
            <div className='chart-container'>
                <div className="chart-container-left-hc-1" id="team-by-geo-chart-container"></div>
            </div>
            <div className='chart-container'>
                <div className="chart-container-left-hc-1" id="headcount-by-dept-chart-container"></div>
            </div>
            <div className='chart-container'>
                <div className="chart-container-left-hc-1" id="headcount-by-seniority-chart-container"></div>
            </div>
            <div className='chart-container'>
                <div className="chart-container-left-hc-1" id="headcount-by-geo-chart-container"></div>
            </div>
            <div style={{ margin:'0 auto', textAlign: 'center'}}>
                <DownloadButton 
                    data = {teamsGrowthData} 
                    companyName={companyName} 
                    buttonText = {'Download Team Growth Data'}
                    fileName = { `${companyName}_team_growth_data.csv` }
                />
                {/* &nbsp;&nbsp;
                <DownloadButton 
                    data = {teamsSeniorityData} 
                    companyName={companyName} 
                    buttonText={'Download Team Seniority Data'}
                    fileName = { `${companyName}_team_growth_seniority_data.csv` }
                /> */}
            </div>
            <div style={{ margin: '10px'}}>
                Notes:
                <ul>
                    <li>LTM = Last Twelve Months</li>
                    <li>Public Profiles = Public profiles on LinkedIn</li>
                </ul>
            </div>
            </div> )}
        </div>
    );
};

export default UniverseCompanyTeamGrowthChartComponent;
