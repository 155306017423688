export function getApiServrUrl() {
    let env = process.env.REACT_APP_ENV;
    let nodeEnv = process.env.NODE_ENV;
    console.log("Running environment: " + env);
    console.log("Running node environment: " + nodeEnv);
    switch (env) {
        case "development":
            return process.env.REACT_APP_API_SERVER_URL_DEV;
        case "staging":
            return process.env.REACT_APP_API_SERVER_URL_STAGING;
        case "production":
            return process.env.REACT_APP_API_SERVER_URL_PROD;
        default:
            return process.env.REACT_APP_API_SERVER_URL_DEV;
    };
};

export function getGoogleAnalyticsId() {
    let env = process.env.REACT_APP_ENV;
    switch (env) {
        case "development":
            return process.env.REACT_APP_GOOGLE_ANALYTICS_ID_DEV;
        case "staging":
            return process.env.REACT_APP_GOOGLE_ANALYTICS_ID_STAGING;
        case "production":
            return process.env.REACT_APP_GOOGLE_ANALYTICS_ID_PROD;
        default:
            return process.env.REACT_APP_GOOGLE_ANALYTICS_ID_DEV;
    };
};


export function toTitle(str) {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export function numberToCurrencyUSDFormatter(number) {
    let formatter = Intl.NumberFormat('en-US', {'notation': 'compact', 'compactDisplay': 'short', 'style': 'currency', 'currency': 'USD'});
    return formatter.format(number);
}

export function numberFormatter(number) {
    let formatter = Intl.NumberFormat('en-US', {'notation': 'compact', 'compactDisplay': 'short'});
    return formatter.format(number);
}

export function customSort(arr, customSortOrder) {
    // Separate arr into those defined in the customSortOrder and those not
    const inOrder = [];
    const notInOrder = [];
  
    for (let i = 0; i < arr.length; i++) {
      if (customSortOrder.includes(arr[i])) {
        inOrder.push(arr[i]);
      } else {
        notInOrder.push(arr[i]);
      }
    }
  
    // Sort arr defined in customSortOrder
    inOrder.sort((a, b) => {
      return customSortOrder.indexOf(a) - customSortOrder.indexOf(b);
    });
  
    // Sort arr not defined in customSortOrder alphabetically
    notInOrder.sort();
  
    // Combine both arrays
    return [...inOrder, ...notInOrder];
  }

// function to get the last date of the month given string date
export function getLastDateOfMonth(dateString) {
    let date = new Date(dateString);
    let lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return lastDate;
}

export function getFriendlyMetricName(metric) {
    switch (metric) {
        case 'ltm_net_hc_growth':
            return 'Net Headcount Growth (Last 12 Months)';
        case 'ltm_attrition_rate':
            return 'Attrition rate (Last 12 Months)';
        case 'ltm_addition_rate':
            return 'Hiring rate (Last 12 Months)';
        case 'ltm_growth_productivity':
            return 'Growth Prod (Last 12 Months)';
        case 'ltm_avg_net_hc_growth_benchmark':
            return 'Benchmark Avg Net Growth (Last 12 Months)';
        case 'ltm_avg_growth_productivity_benchmark':
            return 'Benchmark Avg Growth Prod (Last 12 Months)';
        case 'ltm_avg_addition_rate_benchmark':
            return 'Benchmark Avg Hiring (Last 12 Months)';
        case 'ltm_avg_attrition_rate_benchmark':
            return 'Benchmark Avg Attrition (Last 12 Months)';
        case 'ltm_company_pct_female':
            return 'Pct Female, Company Avg';
        case 'ltm_attrition_rate_female':
            return 'Pct Female, Attrits (Last 12 months)';
        case 'ltm_addition_rate_female':
            return 'Pct Female, New Hires (Last 12 months)';
        default:
            return metric; // return the metric as is if no friendly name is defined
    }
}