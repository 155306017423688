import { useEffect, useState } from "react";
import Highcharts from 'highcharts';
import HighChartsSankey from 'highcharts/modules/sankey';
import axios from "axios";
import { ClipLoader } from 'react-spinners';
import { useLocation, useSearchParams, NavLink } from "react-router-dom";
import { customSort, getApiServrUrl, getLastDateOfMonth, getGoogleAnalyticsId } from "../utils/utils";
import { DownloadButton } from "./download_button";
import { lineColors, lineStyles } from '../utils/constants';
import Select from 'react-select';


HighChartsSankey(Highcharts);

const CompanyTenureComponent = () => {
    const [tenureData, setTenureData] = useState(null);
    const [tenureDistributionDate, setTenureDistributionDate] = useState(new Date(process.env.REACT_APP_DATA_VERSION_DATE));

    const [geo1Values, setGeo1Values] = useState([]);
    const [deptValues, setDeptValues] = useState([]);
    const [seniorityValues, setSeniorityValues] = useState([]);

    const [selectedRegion, setSelectedRegion] = useState({value: 'All', label: 'All'});
    const [selectedDepartment, setSelectedDepartment] = useState({value: 'All', label: 'All'});
    const [selectedSeniority, setSelectedSeniority] = useState({value: 'All', label: 'All'});

    const [isPageLoading, setIsPageLoading] = useState(false);

    const [searchParams] = useSearchParams();
    const location = useLocation();

    const companyName = location.state && location.state.companyName;
    const companyId = searchParams && searchParams.get('company_id') || location.state && location.state.companyId;
    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.accessToken;
    
    const companyGrowthPath = `/company/growth?company_id=${companyId}&access_token=${accessToken}`;
    const teamGrowthPath = `/company/team/growth?company_id=${companyId}&access_token=${accessToken}`;
    const talentFlowPath = `/company/talent?company_id=${companyId}&access_token=${accessToken}`;
    const tenurePath = `/company/tenure?company_id=${companyId}&access_token=${accessToken}`;

    const handleDeptChangeWithSelect = (selectedOption) => {
        if (selectedOption === null || selectedOption.length === 0) {
            console.warn('Atleast one department must be selected');
            return;
        }
        console.log('selectedDepartments: ', selectedOption);
        setSelectedDepartment(selectedOption);
    };

    const handleSeniorityChangeWithSelect = (selectedOption) => {
        if (selectedOption === null || selectedOption.length === 0) {
            console.warn('Atleast one seniority must be selected');
            return;
        }
        console.log('selectedSeniorities: ', selectedOption);
        setSelectedSeniority(selectedOption);
    };

    const handleGeo1ChangeWithSelect = (selectedOption) => {
        if (selectedOption === null || selectedOption.length === 0) {
            console.warn('Atleast one region must be selected');
            return;
        }
        console.log('selectedRegions: ', selectedOption);
        setSelectedRegion(selectedOption);
    };

    const getTenureData = async() => {
        let apiUrl = getApiServrUrl();
        let tenureUrl = apiUrl + process.env.REACT_APP_API_TENURE_ENDPOINT;

        let data = {
            // "company_ids": [919192, , 926354, 950648, 907611, ],
            "company_ids": [parseInt(companyId)],
        };
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
        };

        try {
            // talent in
            console.log(`CompanyTenure: Fetching tenure data from ${tenureUrl}...`);
            const response = await axios.post(tenureUrl, data, config);
            const jsonData = JSON.parse(response.data.data.tenure_history);
            console.log(jsonData);

            let geo1Values = [... new Set(jsonData.map((item) => item.geo1))];
            let sortedGeo1Values = customSort(geo1Values, ['All', 'North America', 'Europe, Middle East & Africa', 'Latin America', 'Asia/Pacific']);
            setGeo1Values(sortedGeo1Values);

            let deptValues = [... new Set(jsonData.map((item) => item.department))];
            const sortedDeptValues = customSort(deptValues, ['All', 'Sales', 'Product']);
            setDeptValues(sortedDeptValues);

            let seniorityValues = [... new Set(jsonData.map((item) => item.seniority))];
            const sortedSeniorityOrder = [
                "All",
                "Individual Contributor",
                "Advisor / Board",
                "Executive",
                "Leadership",
                "Management",
                "Student / Intern",
                "Unsure"
            ];
            const sortedSeniorityValues = customSort(seniorityValues, sortedSeniorityOrder);
            setSeniorityValues(sortedSeniorityValues);

            setTenureData(jsonData);

            setIsPageLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    function getTenureChartOptions() {
        if (tenureData === null || tenureData.length === 0) { 
            return {};
        }

        let filteredData = tenureData.filter(
            (item) => item.geo1 === selectedRegion.value && item.department === selectedDepartment.value && item.seniority === selectedSeniority.value
        );

        console.log('filteredData', filteredData);
		const avgCompanyTenureYearsData = filteredData.map((item) => {
			return [item.the_date, parseFloat(item.avg_company_tenure_years.toFixed(1))];
		});
		const avgCareerTenureYearsData = filteredData.map((item) => {
			return [item.the_date, parseFloat(item.avg_career_tenure_years.toFixed(1))];
		});
        const avgCompanyTenureYearsDataFemale = filteredData.map((item) => {
			return [item.the_date, parseFloat(item.avg_company_tenure_years_f.toFixed(1))];
		});
		const avgCareerTenureYearsDataFemale = filteredData.map((item) => {
			return [item.the_date, parseFloat(item.avg_career_tenure_years_f.toFixed(1))];
		});
        const avgCompanyTenureYearsDataMale = filteredData.map((item) => {
			return [item.the_date, parseFloat(item.avg_company_tenure_years_m.toFixed(1))];
		});
		const avgCareerTenureYearsDataMale = filteredData.map((item) => {
			return [item.the_date, parseFloat(item.avg_career_tenure_years_m.toFixed(1))];
		});
		

		return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: '25%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Tenure',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
			yAxis: {
				title: {
					text: 'Years'
				},
				labels: {
					format: '{value}'
				}
			},
			legend: {
				enabled: true,
			},
            tooltip: {
                shared: true,
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} years</b><br/>',
            },
			
			series: [
				{
					type: 'spline',
					name: 'Avg Career Tenure',
					data: avgCareerTenureYearsData,
                    color: lineColors['Career Tenure'],
				},
                {
					type: 'spline',
					name: 'Avg Career Tenure - Female',
					data: avgCareerTenureYearsDataFemale,
                    color: lineColors['Career Tenure'],
                    dashStyle: lineStyles['Career Tenure Female'],
				},
                {
					type: 'spline',
					name: 'Avg Career Tenure - Male',
					data: avgCareerTenureYearsDataMale,
                    color: lineColors['Career Tenure'],
                    dashStyle: lineStyles['Career Tenure Male'],
				},
				{
					type: 'spline',
					name: 'Avg Company Tenure',
					data: avgCompanyTenureYearsData,
                    color: lineColors['Company Tenure'],
				},
				{
					type: 'spline',
					name: 'Avg Company Tenure - Female',
					data: avgCompanyTenureYearsDataFemale,
                    color: lineColors['Company Tenure'],
                    dashStyle: lineStyles['Company Tenure Female'],
				},
				{
					type: 'spline',
					name: 'Avg Company Tenure - Male',
					data: avgCompanyTenureYearsDataMale,
                    color: lineColors['Company Tenure'],
                    dashStyle: lineStyles['Company Tenure Male'],
				},
				
			],

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                let date = new Date(event.point.x);
                                let dateStr = '' + date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
                                setTenureDistributionDate(new Date(dateStr));
                            }
                        }
                    }
                },
            },

            loading: {
                hideDuration: 1000,
                showDuration: 1000
            },
	
		};
		
	};

    function getCompanyTenureChartOptions() {
        if (tenureData === null || tenureData.length === 0) { 
            return {};
        }

        const categoriesMap = {
            '0-1':  [], 
            '1-2':  [], 
            '2-3':  [], 
            '3-4':  [], 
            '4-5':  [], 
            '5-10': [], 
            '10+':  [],
        };
        const dataSeries = [];
        let filteredData = tenureData.filter(
            (item) => {
                return item.geo1 === selectedRegion.value
                    && item.department === selectedDepartment.value 
                    && item.seniority === selectedSeniority.value
            } 
        );
        for (let i = 0; i < filteredData.length; i++) {
            let item = filteredData[i];

            let data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_0_1) * 100).toFixed(1))];
            categoriesMap['0-1'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_1_2) * 100).toFixed(1))];
            categoriesMap['1-2'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_2_3) * 100).toFixed(1))];
            categoriesMap['2-3'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_3_4) * 100).toFixed(1))];
            categoriesMap['3-4'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_4_5) * 100).toFixed(1))];
            categoriesMap['4-5'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_5_10 * 100)).toFixed(1))];
            categoriesMap['5-10'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_10_plus) * 100).toFixed(1))];
            categoriesMap['10+'].push(data);
        }

        for (let key in categoriesMap) {
            dataSeries.push({
                name: key + ' years',
                data: categoriesMap[key],
                color: lineColors['Company Tenure' + key],
                type: 'column',
                stacked: true,
                stacking: 'percent',
                pointWidth: 20,
            });
        }

        return {
			chart: {
				zoomType: 'xy',
                type: 'column',
                height: '25%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
            title: {
				text: 'Company Tenure Distribution',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
			yAxis: {
				title: {
					text: 'Distribution',
				},
                labels: {
                    formatter: function() {
                        return this.value + '%';
                    },
                },
			},
			tooltip: {
                pointFormatter: function() {
                    return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.percentage.toFixed(1) + '%</b><br/>';
                },
                shared: true,
            },
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                console.log(event);
                            }
                        }
                    }
                },
            },
	
		};

    };

    function getCareerTenureChartOptions() {
        if (tenureData === null || tenureData.length === 0) { 
            return {};
        }

        const categoriesMap = {
            '0-1':  [], 
            '1-2':  [], 
            '2-3':  [], 
            '3-4':  [], 
            '4-5':  [], 
            '5-10': [], 
            '10+':  [],
        };
        const dataSeries = [];
        let filteredData = tenureData.filter(
            (item) => {
                return item.geo1 === selectedRegion.value
                    && item.department === selectedDepartment.value 
                    && item.seniority === selectedSeniority.value
            } 
        );
        for (let i = 0; i < filteredData.length; i++) {
            let item = filteredData[i];

            let data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_0_1) * 100).toFixed(1))];
            categoriesMap['0-1'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_1_2) * 100).toFixed(1))];
            categoriesMap['1-2'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_2_3) * 100).toFixed(1))];
            categoriesMap['2-3'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_3_4) * 100).toFixed(1))];
            categoriesMap['3-4'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_4_5) * 100).toFixed(1))];
            categoriesMap['4-5'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_5_10 * 100)).toFixed(1))];
            categoriesMap['5-10'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_10_plus) * 100).toFixed(1))];
            categoriesMap['10+'].push(data);
        }

        for (let key in categoriesMap) {
            dataSeries.push({
                name: key + ' years',
                data: categoriesMap[key],
                color: lineColors['Career Tenure' + key],
                type: 'column',
                stacked: true,
                stacking: 'percent',
                pointWidth: 20,
            });
        }

        return {
			chart: {
				zoomType: 'xy',
                type: 'column',
                height: '25%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
            title: {
				text: 'Career Tenure Distribution',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
			},
			yAxis: {
				title: {
					text: 'Distribution',
				},
                labels: {
                    formatter: function() {
                        return this.value + '%';
                    },
                },
			},
			tooltip: {
                pointFormatter: function() {
                    return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.percentage.toFixed(1) + '%</b><br/>';
                },
                shared: true,
            },
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                console.log(event);
                            }
                        }
                    }
                },
            },
	
		};

    };
    useEffect(() => {
        setIsPageLoading(true);
        getTenureData();
    }, []);

    useEffect(() => {
        if (isPageLoading === false) {
            const tenureChartOptions = getTenureChartOptions();
            Highcharts.chart('tenure-chart-container', tenureChartOptions);

            const companyTenureChartOptions = getCompanyTenureChartOptions();
            Highcharts.chart('company-tenure-dist-chart-container', companyTenureChartOptions);

            const careerTenureChartOptions = getCareerTenureChartOptions();
            Highcharts.chart('career-tenure-dist-chart-container', careerTenureChartOptions);
        };

    }, [tenureData, selectedRegion, selectedDepartment, selectedSeniority]);

    const customStyles = {
        control: (provided) => ({
        ...provided,
        width: 200,
        }),
        menu: (provided) => ({
        ...provided,
        width: 200,
        }),
    };

    const trackNavLinkClick = (label) => {
        // Check if gtag function is available
        if (typeof window.gtag === 'function') {
            console.log('TenurePage: Tracking navlink click event with label: ' + label);
            const gAnalyticsId = getGoogleAnalyticsId();
            window.gtag('config', gAnalyticsId, {
                'user_id': accessToken
            });
            window.gtag('set', 'user_properties', {
                'token': accessToken
            });
            window.gtag('event', 'navlink_click', {
                'event_category': 'navigation',
                'event_label': label
            });
        }
    };

    
    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'flex-start', padding: '10px'}}>
                <NavLink 
                    style={{fontWeight: 'bold', color: 'blue', padding: '10px', backgroundColor: 'lightgray', borderRadius: '20px'}}
                    to={companyGrowthPath}
                    state={{companyId: companyId, companyName: companyName, accessToken: accessToken}}
                    onClick={() => trackNavLinkClick(`Company Growth - ${companyName} clicked`)}
                > Company
                </NavLink>
                <div style={{ padding: '10px'}}></div>
                <NavLink 
                    style={{fontWeight: 'bold', color: 'blue', padding: '10px', backgroundColor: 'lightgray', borderRadius: '20px'}}
                    to={teamGrowthPath}
                    state={{companyId: companyId, companyName: companyName, accessToken: accessToken}}
                    onClick={() => trackNavLinkClick(`Teams - ${companyName} clicked`)}
                > Teams
                </NavLink>
                <div style={{ padding: '10px'}}></div>
                <NavLink 
                    style={{fontWeight: 'bold', color: 'blue', padding: '10px', backgroundColor: 'lightgray', borderRadius: '20px'}}
                    to={talentFlowPath}
                    state={{companyId: companyId, companyName: companyName, accessToken: accessToken}}
                    onClick={() => trackNavLinkClick(`Talent Flow- ${companyName} clicked`)}
                > Talent Flow
                </NavLink>
                <div style={{ padding: '10px'}}></div>
                <NavLink 
                    style={{fontWeight: 'bold', color: 'blue', padding: '10px', backgroundColor: 'lightgray', borderRadius: '20px'}}
                    to={tenurePath}
                    state={{companyId: companyId, companyName: companyName, accessToken: accessToken}}
                    onClick={() => trackNavLinkClick(`Tenure - ${companyName} clicked`)}
                > Tenure
                </NavLink>
            </div>
            <div style={{textAlign: 'center'}}>
                <h1 style={{marginTop: '0px', marginBottom: '10px'}}>
                    Employee Tenure: {companyName}
                </h1>
            </div>
            { isPageLoading ? (
                <div style={{ textAlign: "center" }} ><ClipLoader/> </div>
            ) : (
            <div>
            <div className='checkbox-group-container'> 
                <div className='checkbox-container'>
                    <label htmlFor='regions'>Region:</label>
                    &nbsp;&nbsp;
                    <Select
                        name="regions"
                        options={geo1Values.map((value) => ({value: value, label: value}))}
                        value={selectedRegion}
                        onChange={handleGeo1ChangeWithSelect}
                        styles={customStyles}
                    />
                </div>
                <div className='checkbox-container'>
                    <label htmlFor='departments'>Department:</label>
                    &nbsp;&nbsp;
                    <Select
                        name="departments"
                        options={deptValues.map((value) => ({value: value, label: value}))}
                        value={selectedDepartment}
                        onChange={handleDeptChangeWithSelect}
                        styles={customStyles}
                    />
                </div>
                <div className='checkbox-container'>
                    <label htmlFor='seniorities'>Seniority:</label>
                    &nbsp;&nbsp;
                    <Select
                        name="seniorities"
                        options={seniorityValues.map((value) => ({value: value, label: value}))}
                        value={selectedSeniority}
                        onChange={handleSeniorityChangeWithSelect}
                        styles={customStyles}
                    />
                </div>
            </div>
            <br/>
            <div className='chart-container'>
                <div className='chart-container-left-hc-1' id="tenure-chart-container" />
            </div>
            <br/>
            <div className='chart-container'>
                <div className='chart-container-left-hc-1' id="career-tenure-dist-chart-container" />
            </div>
            <br/>
            <div className='chart-container'>
                <div className='chart-container-left-hc-1' id="company-tenure-dist-chart-container" />
            </div>
            <div style={{ margin:'0 auto', textAlign: 'center'}}>
                <DownloadButton 
                    data = {tenureData} 
                    companyName={companyName} 
                    buttonText = {'Download Tenure Data'}
                    fileName = { `${companyName}_tenure_data.csv` }
                    tenure = {true}
                />
            </div>
            <div style={{ margin: '10px'}}>
                Notes:
                <ul>
                    <li>Data as of {getLastDateOfMonth(process.env.REACT_APP_DATA_VERSION_DATE).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</li>
                    <li>All data and analysis is based on publicly available profiles</li>
                </ul>
            </div>
            </div> )}
        </div>
    );
};

export default CompanyTenureComponent;
