import { PageLayout } from "../components/page-layout"
import UniverseChartComponent from "../components/universe_chart";

export const UniverseChartPage = () => {
    return (
        <PageLayout>
            <UniverseChartComponent />
        </PageLayout>
    );
};
