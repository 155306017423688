import { PageLayout } from "../components/page-layout";
import UniverseCompanyTalentComponent from "../components/universe_company_talent_chart";

export const UniverseCompanyTalentPage = () => {
    return (
        <PageLayout>
            <UniverseCompanyTalentComponent />
        </PageLayout>
    );
};
